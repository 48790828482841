import { Type } from '@angular/core';
import { Action } from '@ngrx/store';

import { SnackbarComponent } from './snackbar-component';
import { SnackbarRegistry } from './snackbar-registry';

export enum SnackbarActionTypes {
  RemoveSnackbar = '[SnackbarAction] Remove snackbar',
  ShowSnackbar = '[SnackbarAction] Show snackbar'
}

export class RemoveSnackbar implements Action {
  public readonly type = SnackbarActionTypes.RemoveSnackbar;

  constructor(public id: string) {
    SnackbarRegistry.unregister(id);
  }
}

export class ShowSnackbar<C> implements Action {
  public readonly type = SnackbarActionTypes.ShowSnackbar;

  /**
   * Action to show or update a snackbar. If snackbar with id already exists, it will only call .configure with the new config.
   * To successfully use ShowSnackbar action with the element tag, some steps must be done a priori:
   *   1. Create a component which implements {@link SnackbarElementComponent}. Only components implementing the {@link SnackbarElementComponent} interface
   * will be successfully registered and displayed.
   *   2. Create a custom element, e.g. `customElements.define('some-element-tag', createCustomElement(SnackbarElementTagComponent, { injector: this.injector
   * }))`
   *   3. Dispatch {@link ShowSnackbar} action: `store.dispatch(new ShowSnackbar('snackbarId', 'some-element-tag',
   * SnackbarConfiguration.success('LOCALIZED_TITLE')))`
   *
   * @param id of snackbar
   * @param componentOrElementTag type of component used to dynamically create instance of that component, or angular element in case of element tag.
   * @param config which will be passed to the component
   * @param placeOnTop whether the snackbar should be placed on top of the others or at the bottom
   */
  constructor(public id: string, componentOrElementTag: Type<SnackbarComponent<C>> | string, public config: C, public placeOnTop = true) {
    typeof componentOrElementTag === 'string' ? SnackbarRegistry.registerAngularElement(id, componentOrElementTag) :
    SnackbarRegistry.registerComponent(id, componentOrElementTag);
  }
}

export type SnackbarActions = RemoveSnackbar | ShowSnackbar<any>;

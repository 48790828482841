import { schema } from 'normalizr';

import { ExperiencePermissions, InvitationStatus } from '@celum/authentication';
import { Entity, EntityRegistry, EntityTranslator } from '@celum/ng2base';

export const ACCOUNT_USER = `AccountUser`;

export enum UserState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED'
}

export interface AccountUser extends Entity {
  firstName: string;
  lastName: string;
  email: string;
  profilePictureDownloadLink: string;
  status: UserState;
  invitationStatus: InvitationStatus;
  experiencePermissions: ExperiencePermissions[];
}

export const accountUserTranslator: EntityTranslator<AccountUser> = (json: any) => ({
  id: json.oid ?? json.id,
  typeKey: ACCOUNT_USER,
  firstName: json.firstName,
  lastName: json.lastName,
  email: json.email,
  status: json.status,
  invitationStatus: json.invitationStatus,
  profilePictureDownloadLink: json.profilePictureDownloadLink,
  experiencePermissions: json.experiencePermissions
});

const accountUserSchema = new schema.Entity<AccountUser>(ACCOUNT_USER);

EntityRegistry.register<AccountUser>({
  typeKey: ACCOUNT_USER,
  translator: accountUserTranslator,
  schema: accountUserSchema
});

<cdk-virtual-scroll-viewport class="virtual-list_viewport celum-scrollbar native"
                             hasOverflow
                             [class.celum-scrollbar--dark]="darkScrollbar"
                             [itemSize]="itemHeightPx"
                             [maxBufferPx]="itemHeightPx * 2"
                             [minBufferPx]="itemHeightPx"
                             [ngStyle]="scrollContainerStyles"
                             (click)="clickHandler?.onEmptySpaceClick($event)"
                             (scrolledIndexChange)="virtualScrolled()">

  <!--  Disable template caching as it does not work for dynamic use cases (which we mostly have by default) https://github.com/angular/components/issues/16330#issuecomment-514591282-->
  <ng-container *ngIf="((selectionHandler?.selection$ | async) || []) as selection">
    <div *cdkVirtualFor="let item of items; trackBy: trackByFn; templateCacheSize:0; let index = index"
         #listItem
         click-tap (onClick)="clickHandler?.onClick($event, item)" (onTap)="clickHandler?.onTap($event, item)"
         [attr.data-item-id]="$any(item).id ?? ''"
         [class.selected]="selection | wrapFn: isSelected: item:selectionHandler"
         (contextmenu)="onContextMenu($event, item)"
         (onDoubleClick)="onDoubleClick($event, item)">

      <ng-container *ngTemplateOutlet="templateRef; context: {$implicit:item, index: index}"></ng-container>
    </div>
  </ng-container>

  <ng-container [ngTemplateOutlet]="hasMoreBottomTemplate"></ng-container>
</cdk-virtual-scroll-viewport>

<ng-template #hasMoreBottomTemplate>
  <list-loading-spinner *ngIf="hasMoreBottom" class="virtual-list_spinner" [color]="'accent'">
  </list-loading-spinner>
</ng-template>

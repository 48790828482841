import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidatorFn } from '@angular/forms';

export function notIn(notAllowed: string[]): ValidatorFn {
  // do not return function inline -> ngc doesn't like that
  const fn = (control: AbstractControl): { [key: string]: any } => {
    const value: string = (control && control.value) ? (control.value as string).toLowerCase() : 'A';
    const notAllowedList: string[] = notAllowed.map(v => v.toLowerCase());

    return notAllowedList.indexOf(value) !== -1 ? {
      notIn: {
        valid: false,
        messageKey: 'VALIDATION.NOT_IN'
      }
    } : null;
  };

  return fn;
}

@Directive({
             selector: '[notIn][ngModel]',
             providers: [
               {
                 provide: NG_VALIDATORS,
                 useValue: notIn,
                 multi: true
               }
             ],
             standalone: false
           })
export class NotInValidator {

}

<div class="progress-snackbar_wrapper" [class.progress-snackbar_wrapper--determinate]="isDeterminate">

  <ng-container *ngIf="!isDeterminate">
    <div class="progress-snackbar_wrapper-spinner" [style.background-color]="config.iconBackgroundColor">
      <mat-progress-spinner diameter="20" mode="indeterminate"></mat-progress-spinner>
    </div>
  </ng-container>

  <ng-container *ngIf="isDeterminate">
    <mat-progress-bar mode="determinate" [value]="config.value"></mat-progress-bar>
  </ng-container>

  <snackbar [configuration]="config" (snackbarDismissed)="dismiss.emit()"></snackbar>
</div>

<div
    #panelTrigger="cdkOverlayOrigin"
    cdkOverlayOrigin
    class="systembar-notifications_notifications-wrapper"
    [class.systembar-notifications_notifications-wrapper--active]="isPanelOpen()"
    [matTooltip]="'SHARED.SYSTEM_BAR.NOTIFICATIONS' | translate"
    (click)="isPanelOpen.set(!isPanelOpen())">
  <div class="systembar-notifications_notifications-logo">
    <celum-icon
        #notificationIcon
        [matBadge]="count$ | async"
        [configuration]="notificationIconConfiguration">
    </celum-icon>
  </div>
</div>

<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOpen]="isPanelOpen()"
    [cdkConnectedOverlayOrigin]="panelTrigger"
    [cdkConnectedOverlayPanelClass]="'systembar-notifications_notifications-panel'"
    (detach)="isPanelOpen.set(false)"
    (overlayOutsideClick)="isPanelOpen.set(false)">
</ng-template>
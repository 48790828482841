import { Injectable } from "@angular/core";
import { map, Observable, timer } from "rxjs";

import { CelumPropertiesProvider } from "@celum/core";

@Injectable({ providedIn: 'root' })
export class NotificationsCountService {
  public pollForNotificationCount(): Observable<string> {
    return timer(0, CelumPropertiesProvider.properties.systemBar.notificationUpdateInterval).pipe(
      map(() => {
        // TODO: Replace with SBEN call in WR2-15902
        const notifNum = Math.floor(Math.random() * 150);
        if (notifNum === 0) {
          return null;
        }
        return notifNum > 99 ? '99+' : notifNum.toString()
      })
    );
  }
}
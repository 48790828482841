import { EventEmitter, Injectable } from '@angular/core';
import { combineLatest, distinctUntilChanged, first, map, Observable, switchMap, take, tap } from 'rxjs';

import {
  AccountAccess,
  AuthService,
  CHRepository,
  ExperienceLicense,
  isContentHubLicense,
  License,
  TenantService,
  UserDetails,
  UserService,
  WorkroomsLicense
} from '@celum/authentication';
import { CelumPropertiesProvider, isTruthy } from '@celum/core';
import {
  Avatar,
  CONTENT_TAB_ITEM,
  EXPERIENCE_TAB_ITEM,
  Languages,
  LIBRARIES_TAB_ITEM,
  ServiceTabItem,
  ServiceTabItemConfiguration,
  SimpleContextMenuItem,
  Systembar,
  WORKROOMS_TAB_ITEM
} from '@celum/internal-components';
import { BrowserUtil, TranslationHelper } from '@celum/ng2base';
import { Language } from '@celum/shared/domain';

import { SystembarConfiguration } from './systembar.component';

export interface SystembarViewModel {
  systemBar: Systembar;
  user: UserDetails;
  token: string;
}

@Injectable()
export class SystembarService {
  public vm$: Observable<SystembarViewModel>;

  public loadingReady = new EventEmitter<boolean>();

  private configuration: SystembarConfiguration;

  private activeAccountAccesses$: Observable<AccountAccess[]> = this.authService.isAuthenticated$.pipe(
    isTruthy(),
    switchMap(() => this.userService.activeAccountAccesses$),
    take(1)
  );

  private serviceConfiguration$ = this.activeAccountAccesses$.pipe(
    map(activeAccountAccesses => {
      const currentApplicationName = CelumPropertiesProvider.properties.applicationName;
      const currentTenantId = this.tenantService.getCurrentTenantId();

      const chTabItem = this.createTabItem(
        CONTENT_TAB_ITEM,
        this.getContentHubContextMenuItems(activeAccountAccesses),
        currentApplicationName === CONTENT_TAB_ITEM.text.toLowerCase()
      );

      const otherTabItems = [WORKROOMS_TAB_ITEM, LIBRARIES_TAB_ITEM, EXPERIENCE_TAB_ITEM].map(tabItem => {
        const isCurrentApplication = currentApplicationName === tabItem.text.toLowerCase();
        return this.createTabItem(
          tabItem,
          this.getContextMenuItems(activeAccountAccesses, isCurrentApplication, currentTenantId, tabItem),
          isCurrentApplication
        );
      });

      return [chTabItem, ...otherTabItems];
    })
  );

  constructor(
    private translate: TranslationHelper,
    private authService: AuthService,
    private tenantService: TenantService,
    private userService: UserService
  ) {}

  public init(configuration?: SystembarConfiguration): void {
    this.configuration = configuration;

    this.vm$ = combineLatest([
      this.serviceConfiguration$.pipe(isTruthy(), first()),
      this.userService.currentUser$.pipe(distinctUntilChanged()),
      this.authService.isAuthenticated$.pipe(
        isTruthy(),
        switchMap(() =>
          this.authService.getAuthResult().pipe(
            isTruthy(),
            map(result => result.idToken)
          )
        )
      )
    ]).pipe(
      map(([services, user, token]) => {
        const systemBar: Systembar = {
          services,
          avatar: this.getConfigurationForAvatar(configuration),
          helpContextMenuItems: this.getHelpContextMenuItems(this.translate.locale, configuration),
          languages: this.getLanguages(),
          user,
          notificationFeatureEnabled: CelumPropertiesProvider.properties.systemBar.notificationFeatureEnabled
        };

        return {
          systemBar,
          user,
          token
        };
      }),
      tap(() => {
        this.loadingReady.emit(true);
      })
    );
  }

  private getLanguages(): Languages {
    const configuredLanguages = CelumPropertiesProvider.properties.availableLanguages.map(lang => lang as Language);
    const nativeTranslations = TranslationHelper.getUILocalesNativeTranslations(configuredLanguages);

    const availableLanguages = new Map<Language, string>();
    configuredLanguages.forEach(language => availableLanguages.set(language, nativeTranslations[language]));

    return {
      availableLanguages,
      activeLanguageKey: this.translate.locale,
      onClick: (selectedLanguage: string) => this.translate.setLanguage(selectedLanguage)
    };
  }

  private getConfigurationForAvatar(systembarConfiguration?: SystembarConfiguration): Avatar {
    let items: SimpleContextMenuItem[] = [
      {
        text: 'SHARED.SYSTEM_BAR.CLOUD_ACCOUNT',
        onClick: () => window.open(CelumPropertiesProvider.properties.authentication.saccUrl, '_blank')
      }
    ];

    items = systembarConfiguration?.itemProvider?.(items) ?? items;

    return {
      items,
      logoutItem: {
        text: 'COMMON.LOGOUT',
        onClick: () => this.authService.signOut().subscribe(),
        dataComponentId: 'avatar-log-out'
      }
    };
  }

  private getContentHubContextMenuItems(activeAccountAccesses: AccountAccess[] = []): SimpleContextMenuItem[] {
    return (
      activeAccountAccesses
        .flatMap(({ licenses }): License[] => licenses)
        .filter(isContentHubLicense)
        .filter(license => license.active)
        .flatMap((license): CHRepository[] => license.chRepositories)
        // Filter out duplicate entries
        .filter((value, index, array) => index === array.findIndex(item => item.repositoryId === value.repositoryId))
        .sort((repo1, repo2) => (repo1.repositoryId ?? '').localeCompare(repo2.repositoryId))
        .map(chRepository => ({
          text: chRepository.repositoryId,
          onClick: () => {
            this.configuration?.onTenantSwitch?.();
            BrowserUtil.openUrl(chRepository.url);
          }
        }))
    );
  }

  private getContextMenuItems(
    activeAccountAccesses: AccountAccess[],
    isCurrentApplication: boolean,
    currentTenantId: string,
    tabItem: ServiceTabItemConfiguration
  ): SimpleContextMenuItem[] {
    if (!activeAccountAccesses) {
      return [];
    }

    return activeAccountAccesses
      .map(accountAccess => ({ accountAccess, license: UserService.getValidLicenseByType(accountAccess, tabItem.licenseType) }))
      .filter(accountAccessAndLicense => accountAccessAndLicense.license)
      .sort((account1, account2) => account1.accountAccess.accountName.localeCompare(account2.accountAccess.accountName))
      .map(({ accountAccess, license }) => this.getTenantSwitchingContextMenuItems(isCurrentApplication, currentTenantId, accountAccess, license, tabItem));
  }

  private getTenantSwitchingContextMenuItems(
    isCurrentApplication: boolean,
    currentTenantId: string,
    accountAccess: AccountAccess,
    license: License,
    tabItemConfiguration?: ServiceTabItemConfiguration
  ): SimpleContextMenuItem {
    let tenantAwareUrl: string;
    if (isCurrentApplication) {
      tenantAwareUrl = `${window.location.origin}/tenant/${accountAccess.accountId}`;
    } else {
      switch (tabItemConfiguration) {
        case WORKROOMS_TAB_ITEM:
          tenantAwareUrl = (license as WorkroomsLicense).wrTenantAwareUrl;
          break;
        case LIBRARIES_TAB_ITEM:
          tenantAwareUrl = (license as ExperienceLicense).librariesTenantAwareUrl;
          break;
        case EXPERIENCE_TAB_ITEM:
          tenantAwareUrl = (license as ExperienceLicense).experienceTenantAwareUrl;
          break;
        default:
          throw new Error('Unknown or undefined tab item configuration');
      }
    }

    return {
      text: `${accountAccess.accountName}`,
      dataComponentId: accountAccess.accountId === currentTenantId ? 'active-tenant' : '',
      onClick: () => {
        this.configuration?.onTenantSwitch?.();
        BrowserUtil.openUrl(tenantAwareUrl);
      }
    };
  }

  private createTabItem(
    tabItemConfiguration: ServiceTabItemConfiguration,
    contextMenuItems: SimpleContextMenuItem[],
    isCurrentApplication: boolean
  ): ServiceTabItem {
    const serviceTabItem = new ServiceTabItem({
      ...tabItemConfiguration,
      active: isCurrentApplication,
      selected: isCurrentApplication
    });

    serviceTabItem.contextMenuItems = contextMenuItems;
    if (!contextMenuItems || contextMenuItems.length <= 0) {
      serviceTabItem.onClick = () => {
        let url = tabItemConfiguration.applicationUrl;

        if (tabItemConfiguration.fallbackUrlPerLanguage) {
          url = tabItemConfiguration.fallbackUrlPerLanguage[this.translate.locale].replace('${language}', this.translate.locale);
        } else if (isCurrentApplication) {
          url = window.location.origin;
        }

        BrowserUtil.openUrl(url);
      };
    }

    return serviceTabItem;
  }

  private getHelpContextMenuItems(locale: string, systembarConfiguration?: SystembarConfiguration): SimpleContextMenuItem[] {
    const items = [
      {
        text: 'SHARED.SYSTEM_BAR.HELP.HELP_CENTER',
        onClick: () => BrowserUtil.openUrl(CelumPropertiesProvider.properties.systemBar.helpUrls.helpCenter[locale], true)
      },
      {
        text: 'SHARED.SYSTEM_BAR.HELP.TERMS_CONDITIONS',
        onClick: () => BrowserUtil.openUrl(CelumPropertiesProvider.properties.systemBar.helpUrls.termsConditions[locale], true)
      },
      {
        text: 'SHARED.SYSTEM_BAR.HELP.PRIVACY_POLICY',
        onClick: () => BrowserUtil.openUrl(CelumPropertiesProvider.properties.systemBar.helpUrls.privacyPolicy[locale], true)
      }
    ];

    return systembarConfiguration?.helpItemProvider?.(items) ?? items;
  }
}

import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

import { AVATAR_SIZE, AvatarConfiguration, CelumAvatarModule } from '@celum/common-components';
import { AccountUser } from '@celum/shared/domain';
import { accountUserToAvatar, deletedAvatarConfig } from '@celum/shared/util';

@Component({
  selector: 'celum-user-avatar',
  imports: [CelumAvatarModule, MatTooltipModule],
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserAvatarComponent implements OnChanges {
  @Input() public user: Partial<AccountUser>;
  @Input() public accountAccessToken: string;
  @Input() public size: AVATAR_SIZE | number = AVATAR_SIZE.m;
  @Input() public showTooltip = true;
  /**
   * Define custom tooltip text. This will take effect only for active users. Other states' text are based on standard.
   */
  @Input() public tooltipText: string;
  @Input() public showDisplayName = false;

  public config: AvatarConfiguration = deletedAvatarConfig(this.size, this.showTooltip).build();

  public ngOnChanges(): void {
    this.config = this.getAvatarConfiguration();
  }

  private getAvatarConfiguration(): AvatarConfiguration {
    return accountUserToAvatar(this.user, {
      tooltipText: this.tooltipText,
      size: this.size,
      showTooltip: this.showTooltip,
      showDisplayName: this.showDisplayName,
      accountAccessToken: this.accountAccessToken
    });
  }
}

import { Color } from '../model/color';

export class ColorUtil {
  public static getColorForStyle(color: Color): string {
    return `rgb(${color.red},${color.green},${color.blue})`;
  }

  /* Converts a color in hex format (e.g. #ff0000) to hsl format (e.g. hsl(0,100%,50%)) */
  public static hexToHsl(hex: string, precision = 2): string {
    const regex = /^#([0-9A-F]{6})$/i;
    if (!hex || !regex.test(hex)) {
      return null;
    }

    // Convert hex to RGB first
    const r = parseInt(hex.substring(1, 3), 16) / 255;
    const g = parseInt(hex.substring(3, 5), 16) / 255;
    const b = parseInt(hex.substring(5, 7), 16) / 255;

    // Find min and max values of RGB
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    const diff = max - min;

    // Calculate hue, saturation and lightness
    let h = 0;
    let s = 0;
    let l = (max + min) / 2;

    if (diff !== 0) {
      s = l > 0.5 ? diff / (2 - max - min) : diff / (max + min);

      if (max === r) {
        h = (g - b) / diff + (g < b ? 6 : 0);
      } else if (max === g) {
        h = (b - r) / diff + 2;
      } else if (max === b) {
        h = (r - g) / diff + 4;
      }
    }

    h = ColorUtil.roundToDigits(h * 60, precision);
    s = ColorUtil.roundToDigits(s * 100, precision);
    l = ColorUtil.roundToDigits(l * 100, precision);

    return `hsl(${h},${s}%,${l}%)`;
  }

  /* Converts a color in hsl format (e.g. hsl(0,100%,50%)) to hex format (e.g. #ff0000). https://stackoverflow.com/a/44134328/4061620 */
  public static hslToHex(hsl: string): string {
    const regex = /^hsl\(\d{1,3}(?:\.\d+)?,\s*\d{1,3}(?:\.\d+)?%,\s*\d{1,3}(?:\.\d+)?%\)$/i;
    if (!hsl || !regex.test(hsl)) {
      return null;
    }

    const [_, hs, ss, ls] = /hsl\((\d{1,3}(?:\.\d+)?),\s*(\d{1,3}(?:\.\d+)?)%,\s*(\d{1,3}(?:\.\d+)?)%\)/i.exec(hsl);
    const h = +hs;
    const s = +ss;
    let l = +ls;
    l /= 100;
    const a = (s * Math.min(l, 1 - l)) / 100;
    const f = (n: number) => {
      const k = (n + h / 30) % 12;
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color)
                 .toString(16)
                 .padStart(2, '0'); // convert to Hex and prefix "0" if needed
    };
    return `#${f(0)}${f(8)}${f(4)}`;
  }

  /**
   * Sets the given color (hex, hsl or hsla format) as css variables for each hsl part and together as full color.
   * hsla(10, 20%, 40%, 0.5) => --color-h: 10, --color-s: 20%, --color-l: 40%, --color: hsla(var(--color-h), var(--color-s), var(--color-l), 0.5)
   * If the color is a css variable, it is set directly
   * var(--color-primary) => color: var(--color-primary)
   */
  public static setCssVariable(color: string, colorName: string, domElement?: HTMLElement): void {
    const element = domElement || document.documentElement;

    if (color.startsWith('var(')) {
      element.style.setProperty(`${colorName}`, color);
      return;
    }

    const [h, s, l, a] = ColorUtil.toHslaParts(color);
    element.style.setProperty(`${colorName}-h`, `${h}`);
    element.style.setProperty(`${colorName}-s`, `${s}%`);
    element.style.setProperty(`${colorName}-l`, `${l}%`);
    element.style.setProperty(`${colorName}-a`, `${a ?? 1}`);
    element.style.setProperty(`${colorName}`, `hsla(var(${colorName}-h),var(${colorName}-s),var(${colorName}-l), var(${colorName}-a))`);
  }

  /**
   * Adapts the saturation and lightness and opacity of the given color.
   * The color can be passed as hex, hsl or hsla format. The result is returned as hsla format.
   */
  public static adaptColor(color: string, options?: { saturationMultiplier?: number; lightnessMultiplier?: number; opacity?: number }): string {
    const [h, s, l] = ColorUtil.toHslaParts(color);

    const adaptedS = s * (options?.saturationMultiplier ?? 1);
    const adaptedL = l * (options?.lightnessMultiplier ?? 1);
    return `hsla(${h},${adaptedS}%,${adaptedL}%,${options?.opacity ?? 1})`;
  }

  /**
   *  Extract the individual hsla parts from color strings in the hex, hsl or hsla format
   */
  public static toHslaParts(color: string, precision = 2): [number, number, number, number] {
    if (!color) {
      return null;
    }

    const hsl = color.startsWith('#') ? ColorUtil.hexToHsl(color, precision) : color.replace(/\s/g, '');
    const [_, h, s, l, a] = /hsla?\((\d{1,3}(?:\.\d+)?),(\d{1,3}(?:\.\d+)?)%,(\d{1,3}(?:\.\d+)?)%(?:,([\d.]+))?\)/i.exec(hsl);
    return [+h, +s, +l, +(a ?? 1)];
  }

  private static roundToDigits(value: number, digits = 0): number {
    const factor = Math.pow(10, digits);
    return Math.round(value * factor) / factor;
  }
}

import { ChangeDetectorRef, DestroyRef, Directive, ElementRef, HostListener, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatTooltip } from '@angular/material/tooltip';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { BrowserCheck, CelumPropertiesProvider } from '@celum/core';

const defaultDebounceTime = 100;

/**
 * Directive to be applied to an element with a tooltip that should be only shown if the text contained in the element is truncated.
 *
 * ⚠ Requires a [matTooltip] on the element this directive is applied to!
 * ⚠ Requires the element to be configured via CSS to truncate its content if it does not fit!
 *
 * Usage:
 * ```
 * <span class="list-card-item_name"
 *       spaceAwareTooltip
 *       [matTooltip]="entity?.name">{{ entity?.name }}</span>
 * ```
 *
 * The mat tooltip will be only shown if the text in `entity.name` contained in the `span` does not fit the available space. As long as the space is big enough
 * for the text, the tooltip will not be shown!
 * Unless the `matTooltipUntruncated` input is set: this input can be used to show a tooltip in case the text does fit the available space.
 *
 * Example:
 *   You have a span element for the lastname of the user and its width is 5rem.
 *
 *   <span class="lastname"
 *       spaceAwareTooltip
 *       [matTooltip]="person?.lastname"
 *       [matTooltipUntruncated]: "The lastname of the user">{{ person?.lastname }}</span>
 *
 * If the width of the lastname of the user is less or equal 5rem and the width of the span is 5rem, the lastname IS NOT truncated and the tooltip will be
 * shown with the text
 * 'The lastname of the user' on hover. If the width of the lastname of the user is greater than 5rem, the lastname IS truncated and the space aware tooltip
 * will therefore show the full lastname of the user on hover.
 */
@Directive({
             selector: '[spaceAwareTooltip]',
             standalone: false
           })
export class SpaceAwareTooltipDirective implements OnInit {

  /** This tooltip is shown if the text contained in the element IS truncated. It can be used to, for example, show the full text on hover. */
    // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('matTooltip') public tooltipText: string;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('matTooltipShowDelay') public showDelay: number;

  /**
   * This tooltip is shown if the text contained in the element IS NOT truncated. It can be used to, for example, show a generic description of the element on
   * hover (e.g. 'This is the lastname of the user').
   */
  @Input() public matTooltipUntruncated: string;

  /**
   * @deprecated
   */
  /** Deprecated: This property is not used as there is no subscribing to resize events anymore. */
  @Input() public spaceAwareDebounceTime = 100;

  private destroyRef = inject(DestroyRef);

  private hovered$ = new BehaviorSubject<boolean>(false);

  constructor(private element: ElementRef, private changeDetector: ChangeDetectorRef, private matTooltip: MatTooltip) {}

  @HostListener('mouseenter', ['$event'])
  @HostListener('mouseleave', ['$event'])
  public onHover(event: MouseEvent): void {
    this.hovered$.next(event.type === 'mouseenter');
  }

  public ngOnInit(): void {
    this.clearMessage();

    if (this.directiveDisabled()) {
      return;
    }

    this.hovered$.pipe(debounceTime(defaultDebounceTime), takeUntilDestroyed(this.destroyRef)).subscribe(
      hovered => hovered ? this.checkTruncated(this.element.nativeElement) : this.clearMessage()
    );
  }

  // early exit on Safari due to https://github.com/angular/components/issues/7469
  private directiveDisabled(): boolean {
    return CelumPropertiesProvider.properties.disableSpaceAwareTooltipOnSafari && BrowserCheck.isSafari();
  }

  private checkTruncated(element: Element): void {
    let isTruncated;
    // scrollWidth and scrollHeight are always rounded to the nearest integer, which works fine for multi-line texts, because there is a significant
    // height difference between the scrollHeight and the clientHeight. But it is a problem in case of one-line texts,
    // because it can happen that the scrollWidth is the same as clientWidth, even if the text is truncated.
    // e.g scrollWidth is 100px and clientWidth is 100px, but the text is actually 100.2px long and therefore truncated.
    // So for one-liner texts we compare the element width to the width of a cloned element with the same text, but the cloned element's width
    // is the full width of the text. And element width is not rounded.
    if (parseInt(getComputedStyle(element).webkitLineClamp, 10) > 1) {
      isTruncated = element.clientHeight < element.scrollHeight;
    } else {
      const clonedElement = element.cloneNode(true) as HTMLElement;
      clonedElement.style.font = getComputedStyle(element).font;
      clonedElement.style.display = 'flex';
      clonedElement.style.maxWidth = 'unset';
      clonedElement.style.width = 'max-content';
      clonedElement.style.visibility = 'hidden';

      document.body.appendChild(clonedElement);

      isTruncated = clonedElement.getBoundingClientRect().width > element.getBoundingClientRect().width;

      clonedElement.remove();
    }

    this.matTooltip.message = isTruncated ? this.tooltipText : '';

    if (isTruncated) {
      const showDelay = this.showDelay ?? this.matTooltip.showDelay;
      this.matTooltip.show(showDelay > defaultDebounceTime ? showDelay - defaultDebounceTime : 0);
    } else {
      if (this.matTooltipUntruncated) {
        this.matTooltip.message = this.matTooltipUntruncated;
      } else {
        this.matTooltip.hide();
      }
    }

    this.changeDetector.markForCheck();
  }

  private clearMessage(): void {
    this.matTooltip.message = this.matTooltipUntruncated || '';
    this.matTooltip.hide();
  }
}

import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';

import { formatNumber, ReactiveService } from '@celum/core';

@Pipe({
        name: 'formatNumber',
        pure: true,
        standalone: false
      })
export class FormatNumberPipe extends ReactiveService implements PipeTransform, OnDestroy {

  constructor(protected translate: TranslateService, private changeDetector: ChangeDetectorRef) {
    super();
    this.translate.onLangChange.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.changeDetector.markForCheck());
  }

  public transform(input: number | string, locale: string): string {
    return formatNumber(input, locale);
  }

  public ngOnDestroy(): void {
    super.destroy();
  }
}

import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, ComponentRef, ElementRef, HostBinding, OnDestroy, OnInit, Type, ViewChild, ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { take, takeUntil } from 'rxjs/operators';

import { RemoveSnackbar, SnackbarComponent, SnackbarElementComponent, SnackbarRegistry } from '@celum/common-components';
import { ComponentCreatorUtil, ElementTag, ReactiveComponent } from '@celum/ng2base';

import { getSnackbars } from '../store/snackbar-state';

@Component({
             selector: 'snackbar-list',
             template: `
               <ng-container #snackbarList></ng-container>`,
             styleUrls: ['./snackbar-list.less'],
             changeDetection: ChangeDetectionStrategy.OnPush,
             encapsulation: ViewEncapsulation.None,
             standalone: false
           })
export class SnackbarList extends ReactiveComponent implements OnInit, OnDestroy {

  public snackbars: { [key: string]: ComponentRef<SnackbarComponent<any>> | ElementTag<SnackbarElementComponent<any>> } = {};

  @HostBinding('class.snackbar-list') public hostCls = true;

  @ViewChild('snackbarList', { read: ViewContainerRef, static: false }) private snackbarListContainerRef: ViewContainerRef;

  constructor(private changeDetector: ChangeDetectorRef, private store: Store<any>, private elementRef: ElementRef) {
    super();
  }

  public ngOnInit(): void {
    this.store.pipe(
      select(getSnackbars),
      takeUntil(this.unsubscribe$)
    ).subscribe(snackbars => {
      const added = snackbars.filter(snackbar => !this.snackbars[snackbar.id]);
      const modified = snackbars.filter(snackbar => this.snackbars[snackbar.id]);
      const removed = Object.keys(this.snackbars).filter(id => !snackbars.find(snackbar => snackbar.id === id));

      added.forEach(
        snackbar => this.createSnackbar(snackbar.id, SnackbarRegistry.getComponentOrElementTag(snackbar.id), { ...snackbar.config }, snackbar.placeOnTop));
      modified.forEach(snackbar => {
        if (this.snackbars[snackbar.id] instanceof ComponentRef) {
          const componentRefSnackbar = this.snackbars[snackbar.id] as ComponentRef<SnackbarComponent<any>>;
          componentRefSnackbar.instance.configure({ ...snackbar.config });
        } else {
          ComponentCreatorUtil.updateExtensionProperties(this.snackbars[snackbar.id] as ElementTag<SnackbarElementComponent<any>>, { config: snackbar.config });
        }
      });
      removed.forEach(id => this.destroySnackbar(id));
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });
  }

  private createSnackbar(id: string, componentOrElementTag: Type<SnackbarComponent<any>> | string, config: any, placeOnTop: boolean): void {
    if (!componentOrElementTag) {
      console.error(`No component or elementTag registered for snackbar with id ${id}`);
      return;
    }

    const index = placeOnTop ? 0 : this.snackbarListContainerRef.length;

    if (typeof componentOrElementTag === 'string') {
      const angularElement = ComponentCreatorUtil.createAngularElement<SnackbarElementComponent<any>>(componentOrElementTag, this.elementRef.nativeElement,
                                                                                                      index);
      this.snackbars[id] = angularElement;
      ComponentCreatorUtil.updateExtensionProperties(angularElement, { config });
      const dismiss$ = ComponentCreatorUtil.listenToExtensionOutput(angularElement, 'dismiss');
      dismiss$?.pipe(take(1)).subscribe(() => this.store.next(new RemoveSnackbar(id)));
    } else {
      const componentRef: ComponentRef<SnackbarComponent<any>> = this.snackbarListContainerRef.createComponent(componentOrElementTag, { index });
      this.snackbars[id] = componentRef;

      componentRef.instance.configure({ ...config }); // as this comes from the store and is not allowed to be modified... make sure that actual snackbar cannot
                                                      // produce errors
      componentRef.instance.dismiss.pipe(take(1)).subscribe(() => this.store.next(new RemoveSnackbar(id)));
    }

    this.changeDetector.detectChanges();
  }

  private destroySnackbar(id: string): void {
    const componentOrAngularElement = this.snackbars[id];
    delete this.snackbars[id];
    if (componentOrAngularElement instanceof ComponentRef) {
      componentOrAngularElement.destroy();
    } else {
      componentOrAngularElement.remove();
    }
  }
}

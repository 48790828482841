import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { CelumDirectivesModule } from '@celum/ng2base';

@Component({
             selector: 'list-loading-spinner',
             templateUrl: './list-loading-spinner.component.html',
             styleUrls: ['./list-loading-spinner.component.less'],
             imports: [
               CommonModule,
               MatProgressSpinnerModule,
               CelumDirectivesModule
             ],
             changeDetection: ChangeDetectionStrategy.OnPush
           })
export class ListLoadingSpinnerComponent {

  @Input() public color: ThemePalette = 'primary';

  protected spinnerVisible: boolean;
}

import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, ViewEncapsulation } from '@angular/core';

import { IconConfiguration } from '@celum/common-components';

@Component({
             selector: 'celum-collapse-button',
             templateUrl: './collapse-button.component.html',
             styleUrls: ['./collapse-button.component.less'],
             changeDetection: ChangeDetectionStrategy.OnPush,
             encapsulation: ViewEncapsulation.None,
             standalone: false
           })
export class CelumCollapseButtonComponent {
  @Input() public collapsed = false;
  @Input() public verticalOrientation = false;

  @Output() public readonly changed = new EventEmitter<void>();

  @HostBinding('class.collapse-button') public hostCls = true;

  public icons = {
    arrow: IconConfiguration.small('play-m')
  };

}

import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

import { IconConfiguration } from '@celum/common-components';

import { SimpleContextMenuItem } from '../../model/simple-content-menu-item';

@Component({
             selector: 'systembar-help',
             templateUrl: './help-component.html',
             styleUrls: ['./help-component.less'],
             changeDetection: ChangeDetectionStrategy.OnPush,
             encapsulation: ViewEncapsulation.None,
             standalone: false
           })
export class HelpComponent {
  @Input() public contextMenuItems: SimpleContextMenuItem[];

  public questionnaireIconConfiguration = new IconConfiguration('cc-questionnaire').withIconDimensions(10, 14).withColor('#FFF');

  @HostBinding('class.systembar-help') public hostCls = true;
}

import { GroupInfo } from './group/group-info';
import { OperationGroup } from './group/operation-group';
import { GroupDefinition } from './model/group-definition';
import { OperationDefinition } from './model/operation-definition';
import { OperationsManager } from './operations-manager';
import { DataUtil } from '../../core/util/data-util';

export type OperationGroupInfo = {
  group: OperationGroup,
  operationsAndSubGroups: (OperationDefinition | OperationGroupInfo)[]
};

export class OperationsUtil {

  public static sortOperationDefinitions(definitions: OperationDefinition[]): OperationDefinition[] {
    return OperationsUtil.groupOperationDefinitions(definitions).reduce((acc, val) => {
      return [...acc, ...OperationsUtil.flattenGroup(val)];
    }, []);
  }

  public static groupOperationDefinitions(operationDefinitions: OperationDefinition[]): OperationGroupInfo[] {
    // group operations into groups
    const definitionsByGroup = this.collectionDefinitionsByGroup(operationDefinitions);

    const allGroups = [...definitionsByGroup.keys()].map(key => OperationsManager.getGroup(key));
    const { groupsByParent, rootGroups } = this.findRootGroupsAndCollectByParent(allGroups);

    // start with root groups (there should be just one, as we cannot sort them without sort info!)
    return rootGroups.map(rootGroup => this.createTreeFromDefinitions(groupsByParent, definitionsByGroup, rootGroup));
  }

  private static flattenGroup(groupInfo: OperationGroupInfo): OperationDefinition[] {
    return groupInfo.operationsAndSubGroups.reduce((acc, val) => {
      if ((val as OperationGroupInfo).operationsAndSubGroups) {
        return [...acc, ...OperationsUtil.flattenGroup(val as OperationGroupInfo)];
      }
      return [...acc, val];
    }, []);
  }

  private static findRootGroupsAndCollectByParent(allGroups: GroupDefinition[]): {
    rootGroups: GroupDefinition[],
    groupsByParent: Map<string, GroupDefinition[]>
  } {
    const groupsByParent = new Map<string, GroupDefinition[]>();
    const rootGroups: GroupDefinition[] = [];

    allGroups.forEach(groupInfo => {
      // if the group is in another group...
      if (groupInfo.groupInfo) {
        !groupsByParent.has(groupInfo.groupInfo.groupId) && groupsByParent.set(groupInfo.groupInfo.groupId, []);

        groupsByParent.get(groupInfo.groupInfo.groupId).push(groupInfo);
      } else {
        rootGroups.push(groupInfo);
      }
    });

    return { groupsByParent, rootGroups };
  }

  private static collectionDefinitionsByGroup(operationDefinitions: OperationDefinition[]): Map<string, OperationDefinition[]> {
    const definitionsByGroup = new Map<string, OperationDefinition[]>();

    operationDefinitions.forEach(def => {
      const groupId = def.groupInfo.groupId;

      if (!definitionsByGroup.has(groupId)) {
        definitionsByGroup.set(groupId, []);

        OperationsUtil.addAllParentGroups(groupId, definitionsByGroup);
      }

      definitionsByGroup.get(groupId).push(def);
    });

    return definitionsByGroup;
  }

  private static addAllParentGroups(groupId: string, definitionsByGroup: Map<string, OperationDefinition[]>): void {
    const group = OperationsManager.getGroup(groupId);
    const parentGroupId = group.groupInfo?.groupId;

    if (!parentGroupId || definitionsByGroup.has(parentGroupId)) {
      return;
    }

    definitionsByGroup.set(parentGroupId, []);

    OperationsUtil.addAllParentGroups(parentGroupId, definitionsByGroup);
  }

  private static createTreeFromDefinitions(groupsByParent: Map<string, GroupDefinition[]>, definitionsByGroup: Map<string, OperationDefinition[]>,
                                           group: GroupDefinition): OperationGroupInfo {
    const groupsAndOperations = OperationsUtil.sortOperations(
      [...(groupsByParent.get(group.group.key) ?? []), ...(definitionsByGroup.get(group.group.key) ?? [])]);

    return {
      group: group.group,
      operationsAndSubGroups: groupsAndOperations.map(groupOrOperation => {
        if (isGroup(groupOrOperation)) {
          return this.createTreeFromDefinitions(groupsByParent, definitionsByGroup, groupOrOperation);
        }

        return groupOrOperation;
      }).filter(groupOrOperation => {
        // filter out groups without any children
        return !((groupOrOperation as OperationGroupInfo).operationsAndSubGroups &&
                 DataUtil.isEmpty((groupOrOperation as OperationGroupInfo).operationsAndSubGroups));
      })
    };
  }

  /**
   * Property `groupInfo` is optional here to satisfy the compiler, although we know that everything that is put into this method definitely has a `groupInfo`
   * property assigned!
   */
  private static sortOperations<E extends { groupInfo?: GroupInfo }>(operations: E[]): E[] {
    return operations.sort((a, b) => a.groupInfo.sortOrder - b.groupInfo.sortOrder);
  }

}

function isGroup(entity: OperationDefinition | GroupDefinition): entity is GroupDefinition {
  return (entity as GroupDefinition).group !== undefined;
}

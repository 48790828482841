import { Pipe, PipeTransform } from '@angular/core';

// purpose of this class is to remove protocols and trailing backslash from url string
@Pipe({
        name: 'stripUrlProtocol',
        standalone: false
      })
export class StripUrlProtocolPipe implements PipeTransform {
  public transform(url: string): string {
    //  remove protocol
    let updatedUrl = url.replace(/(^\w+:|^)\/\//, '');

    // remove trailing backslashes
    updatedUrl = updatedUrl.replace(/\/$/, '');
    return updatedUrl;
  }
}

import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import {
  asyncScheduler,
  catchError,
  defer,
  EMPTY,
  filter,
  finalize,
  map,
  merge,
  Observable,
  observeOn,
  shareReplay,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs';

import {
  MessageDialog,
  MessageDialogConfiguration,
  RemoveSnackbar,
  ShowSnackbar,
  SimpleSnackbar,
  SnackbarConfiguration,
  SnackbarState
} from '@celum/common-components';
import { ExecutableAction, UUIDGenerator } from '@celum/core';
import { ExperienceProperties } from '@celum/experience/domain';
import { PusherService } from '@celum/shared/util';

import { ContentTemplateService } from './content-template.service';
import { showFirstStepSnackbar, showImportProgressSnackbar, showSuccessSnackbar, showWarnOrErrorSnackbar } from './content-templates-import-snackbars.helper';
import { uploadAndWaitForFinish } from './content-templates-import-upload.helper';
import { IMPORT_STATE } from './content-templates-import.constants';
import { ContentTemplate, ContentTemplateErrorCodes, ContentTemplateStatus } from '../../model/content-template.entity';
import { NavigationService } from '../../navigation.service';
import { UploadLogicService } from '../../upload-logic.service';

export type ImportEvents = `${IMPORT_STATE}`;

type ImportInfo = {
  identifier: string;
  contentTemplateId?: string;
  file?: File;
  name: string;
  state: IMPORT_STATE;
};

type ImportServiceStore = { runningImports: Map<string, ImportInfo> };

@Injectable({ providedIn: 'root' })
export class ContentTemplatesImportService extends ComponentStore<ImportServiceStore> {
  public events$: Observable<ImportEvents>;

  private contentTemplateService = inject(ContentTemplateService);
  private store = inject(Store);
  private uploadLogicService = inject(UploadLogicService);
  private matDialog = inject(MatDialog);
  private pusherService = inject(PusherService);
  private navigationService = inject(NavigationService);

  private eventSubj = new Subject<ImportEvents>();
  private finishedImports$: Observable<{ id: string; success: boolean }>;

  private updateState = this.updater(
    (
      state,
      options: {
        identifier: string;
        newState: IMPORT_STATE;
        stateInfo?: Pick<ImportInfo, 'contentTemplateId'>;
      }
    ) => this.updateStateForImportInfo(state, options)
  );

  constructor() {
    super({ runningImports: new Map() });
    this.events$ = this.eventSubj.asObservable();
  }

  public isImportLimitReached(): boolean {
    return this.get().runningImports.size >= ExperienceProperties.properties.contentTemplateImportLimit;
  }

  /**
   * Cancel an import process.
   */
  public cancelImport(contentTemplate: ContentTemplate): void {
    const identifier = this.findImport(contentTemplate.id);

    if (!identifier) {
      return;
    }

    this.cancelProcess(identifier).subscribe();
  }

  /**
   * Import a file as a content template.
   */
  public import(file: File): void {
    if (this.isImportLimitReached()) {
      return;
    }

    if (file.size > ExperienceProperties.properties.contentTemplateImportSizeLimitInBytes) {
      const maxMB = (ExperienceProperties.properties.contentTemplateImportSizeLimitInBytes / 1024 / 1024).toFixed(3);

      showWarnOrErrorSnackbar(this.store, 'error', 'file-too-big', 'EXPERIENCE.CONTENT_TEMPLATES.IMPORT.SNACKBARS.FILE_TOO_BIG', { size: parseFloat(maxMB) });
      return;
    }

    const identifier = UUIDGenerator.generateId();

    this.startImportFlow(identifier, file);
  }

  /**
   * Listen to pusher channels for content template import events.
   * Also loads all content templates for which imports are still running.
   */
  public listenToContentTemplateImports(): void {
    this.finishedImports$ = defer(() =>
      merge(
        this.pusherService.watchPrivateUserChannel<string>('CT_IMPORT_COMPLETED').pipe(map(id => ({ id, success: true }))),
        this.pusherService.watchPrivateUserChannel<string>('CT_IMPORT_ERROR').pipe(map(id => ({ id, success: false })))
      ).pipe(
        catchError(error => {
          // if there are any issues with the pusher connection, we need to handle this here -> inform the user that we do not
          // get any updates
          this.handlePusherBindError(error);

          return EMPTY;
        })
      )
    );

    this.loadRunningImports();
  }

  /**
   * Check whether a given import is finished. An import is considered finished when it is not in the list of running imports anymore.
   * Will emit once, when the import is removed.
   */
  private importFinished$(identifier: string): Observable<void> {
    return this.select(state => state.runningImports).pipe(
      filter(runningImports => !runningImports.has(identifier)),
      map(() => void 0),
      take(1)
    );
  }

  /**
   * Start a new import or re-trigger listening for an already running one.
   * @param identifier  the identifier for the import (for new ones uuid, for existing ones the content template id)
   * @param file        the file to import or the file name (for existing ones)
   */
  private setImportState(identifier: string, file: File | string): void {
    const runningImports = new Map(this.get().runningImports);

    if (typeof file === 'string') {
      runningImports.set(identifier, {
        identifier,
        name: file,
        state: IMPORT_STATE.IMPORTING,
        contentTemplateId: identifier
      });
    } else {
      runningImports.set(identifier, {
        identifier,
        name: file.name,
        file,
        state: IMPORT_STATE.PENDING
      });
    }

    this.patchState({ runningImports });
  }

  private removeImport(identifier: string): void {
    const runningImports = new Map(this.get().runningImports);
    runningImports.delete(identifier);
    this.patchState({ runningImports });
  }

  private findImport(contentTemplateId: string): string {
    const imports = this.get().runningImports;

    return [...imports.keys()].find(identifier => {
      const importInfo = imports.get(identifier);

      return importInfo.contentTemplateId === contentTemplateId;
    });
  }

  private updateStateForImportInfo(
    state: ImportServiceStore,
    options: { identifier: string; newState: IMPORT_STATE; stateInfo?: Pick<Partial<ImportInfo>, 'contentTemplateId'> }
  ): ImportServiceStore {
    const runningImports = new Map(state.runningImports);
    const info = runningImports.get(options.identifier);

    if (!info) {
      return state;
    }

    runningImports.set(options.identifier, {
      ...info,
      state: options.newState,
      ...options.stateInfo
    });

    return { runningImports };
  }

  /**
   * Trigger listening to a running import. This means we have a content template entity in the system, but the scene import is still running.
   * "Register" the import in the import store, show the progress snackbar and watch the pusher channel for fail/success information.
   */
  private triggerListenToRunningImportProgress(contentTemplate: ContentTemplate) {
    this.setImportState(contentTemplate.id, contentTemplate.name);
    showImportProgressSnackbar(this.store, contentTemplate.name, contentTemplate.id, () => this.cancelProcess(contentTemplate.id));

    this.waitForImportToFinish(contentTemplate.id, contentTemplate.id, contentTemplate.name)
      .pipe(finalize(() => this.removeImport(contentTemplate.id)))
      .subscribe();
  }

  /**
   * Steps:
   * - create template entity to import the file to -> returns template entity
   * - upload file -> returns asset id
   * - createSceneFromImport (triggers the import flow and creates the content template entity)
   * - BE notifies via pusher notification when import has finished successfully/failed (only at this point, there is a scene for the content template)
   */
  private startImportFlow(identifier: string, file: File): void {
    showFirstStepSnackbar(this.store, file, identifier, () => this.cancelUpload(identifier));

    this.setImportState(identifier, file);

    this.createContentTemplateForImport(file, identifier)
      .pipe(
        switchMap(contentTemplate => this.upload(identifier, contentTemplate, file)),
        switchMap(({ assetId, contentTemplate }) => this.triggerActualImport(file, identifier, assetId, contentTemplate)),
        tap(() => this.eventSubj.next(IMPORT_STATE.CONVERSION_STARTED)),
        switchMap(contentTemplate => this.waitForImportToFinish(identifier, contentTemplate.id, contentTemplate.name)),
        takeUntil(this.importFinished$(identifier)),
        finalize(() => this.removeImport(identifier)),
        catchError(error => {
          this.handleError(identifier, file.name, error);
          return EMPTY;
        })
      )
      .subscribe();
  }

  private createContentTemplateForImport(file: File, identifier: string): Observable<ContentTemplate> {
    const contentTemplate$ = this.contentTemplateService.createForImport(file.name).pipe(
      // I sometimes got into the case that the stream was stopped immediately again because info for this import was not present in the import store yet
      observeOn(asyncScheduler),
      shareReplay(1)
    );

    // in case the import is canceled before we get the response from BE, we need to delete the content template again
    contentTemplate$
      .pipe(
        // if import was cancelled...
        filter(() => !this.get().runningImports.has(identifier)),
        // delete the created content template
        switchMap(contentTemplate => this.contentTemplateService.delete(contentTemplate.id)),
        catchError(error => {
          console.error('ContentTemplatesImportService: deleting content template after cancel failed', error);
          return EMPTY;
        })
      )
      .subscribe();

    return contentTemplate$;
  }

  private waitForImportToFinish(identifier: string, contentTemplateId: string, name: string): Observable<void> {
    return this.finishedImports$.pipe(
      filter(info => info.id === contentTemplateId),
      takeUntil(this.importFinished$(identifier)),
      tap(({ id, success }) => {
        if (!success) {
          this.handleError(identifier, name, null);
          return;
        }

        this.handleImportSuccess(identifier, id, name);
      }),
      map(() => void 0)
    );
  }

  /**
   * Load all content templates for which imports are still running and trigger waiting for their finish "signals".
   */
  private loadRunningImports(): void {
    this.contentTemplateService.find({ statuses: [ContentTemplateStatus.IMPORTING] }).subscribe({
      next: result => result.entities.forEach(contentTemplate => this.triggerListenToRunningImportProgress(contentTemplate)),
      error: error => console.error('ContentTemplatesImportService: loading running imports failed', error)
    });
  }

  /**
   * Trigger the actual import process. After this step, we just wait for the pusher message to know whether the import was successful or not.
   */
  private triggerActualImport(file: File, identifier: string, assetId: string, contentTemplate: ContentTemplate): Observable<ContentTemplate> {
    showImportProgressSnackbar(this.store, file.name, identifier, () => this.cancelProcess(identifier));

    this.updateState({
      identifier,
      newState: IMPORT_STATE.IMPORTING
    });

    return this.contentTemplateService.createSceneFromImport(contentTemplate.id, assetId).pipe(map(() => contentTemplate));
  }

  private upload(identifier: string, contentTemplate: ContentTemplate, file: File): Observable<{ contentTemplate: ContentTemplate; assetId: string }> {
    this.eventSubj.next(IMPORT_STATE.UPLOADING);

    this.updateState({
      identifier,
      newState: IMPORT_STATE.UPLOADING,
      stateInfo: { contentTemplateId: contentTemplate.id }
    });

    return uploadAndWaitForFinish(this.uploadLogicService, file, contentTemplate, this.importFinished$(identifier));
  }

  private showConfirmationDialog({ title, message }: { title: string; message: string }): Observable<boolean> {
    const messageDialogConfiguration = MessageDialogConfiguration.createWarnConfig(message, title)
      .withButtons(['ok', 'cancel'])
      .withOkButtonText('COMMON.CONFIRM')
      .withHideCloseButton();

    return this.matDialog.open(MessageDialog, { data: messageDialogConfiguration }).afterClosed();
  }

  /**
   * Cancel a running import.
   * Note: usually, we should first check with the user whether they really wants to cancel the import!
   */
  private cancelRunningImport(identifier: string, contentTemplateId: string): void {
    this.store.next(new RemoveSnackbar(identifier)); // just to be sure...

    this.removeImport(identifier);

    this.contentTemplateService
      .delete(contentTemplateId)
      .pipe(finalize(() => this.eventSubj.next(IMPORT_STATE.CANCELED)))
      .subscribe({
        error: error => console.error('ContentTemplatesImportService: canceling running import failed. Could not delete template', error)
      });
  }

  /**
   * Process a cancel request for a still running upload.
   * Asks the user for confirmation before actually canceling the upload, then emit the cancel "signal".
   */
  private cancelUpload(identifier: string): Observable<boolean> {
    return this.showConfirmationDialog({
      title: 'EXPERIENCE.CONTENT_TEMPLATES.IMPORT.DIALOGS.CANCEL_UPLOAD.TITLE',
      message: 'EXPERIENCE.CONTENT_TEMPLATES.IMPORT.DIALOGS.CANCEL_UPLOAD.DETAILS'
    }).pipe(tap(confirmed => confirmed && this.executeCancel(identifier)));
  }

  /**
   * Process a cancel request for a still running import.
   * Asks the user for confirmation before actually canceling the import, then execute the cancel.
   */
  private cancelProcess(identifier: string): Observable<boolean> {
    return this.showConfirmationDialog({
      title: `EXPERIENCE.CONTENT_TEMPLATES.IMPORT.DIALOGS.CANCEL_IMPORT.TITLE`,
      message: 'EXPERIENCE.CONTENT_TEMPLATES.IMPORT.DIALOGS.CANCEL_IMPORT.DETAILS'
    }).pipe(tap(confirmed => confirmed && this.executeCancel(identifier)));
  }

  private executeCancel(identifier: string): void {
    const importInfo = this.get().runningImports.get(identifier);

    console.debug('ContentTemplateImportService: cancel import', identifier, importInfo);

    if (!importInfo) {
      return;
    }

    if (importInfo.contentTemplateId) {
      this.cancelRunningImport(identifier, importInfo.contentTemplateId);
    } else {
      this.removeImport(identifier);
    }
  }

  private handleImportSuccess(identifier: string, id: string, name: string): void {
    showSuccessSnackbar(this.store, identifier, name, () => this.navigationService.openContentTemplateDesigner(id));

    this.removeImport(identifier);

    this.contentTemplateService.findOne({ id }).once$.subscribe({
      error: error => console.error('ContentTemplatesImportService: loading content template after successful import failed', error)
    });
  }

  private handleError(identifier: string, name: string, error: any): void {
    if (error?.error === ContentTemplateErrorCodes.CONTENT_TEMPLATE_MAX_CONCURRENT_IMPORTS_REACHED) {
      this.showWarnOrErrorSnackbar(identifier, 'warn');
    } else {
      this.showWarnOrErrorSnackbar(identifier, 'error', name);
    }

    this.removeImport(identifier);

    this.eventSubj.next(IMPORT_STATE.IMPORT_FAILED);

    console.error('ContentTemplatesImportService: importing failed', error);
  }

  private handlePusherBindError(error: any): void {
    console.error('ContentTemplatesImportService: listening to pusher channels failed', error);

    const errorConfig = SnackbarConfiguration.error('EXPERIENCE.CONTENT_TEMPLATES.IMPORT.SNACKBARS.PUSHER_ERROR');
    errorConfig.withDescription('EXPERIENCE.CONTENT_TEMPLATES.IMPORT.SNACKBARS.PUSHER_ERROR_INFO');
    errorConfig.withActions({
      [SnackbarState.ERROR]: [new ExecutableAction(() => window.location.reload(), 'COMMON.REFRESH_PAGE')]
    });

    this.store.next(new ShowSnackbar('error-listening-to-pusher-events-for-import', SimpleSnackbar, errorConfig));
  }

  private showWarnOrErrorSnackbar(identifier: string, stateType: 'warn' | 'error', fileName?: string): void {
    showWarnOrErrorSnackbar(
      this.store,
      stateType,
      identifier,
      `EXPERIENCE.CONTENT_TEMPLATES.IMPORT.SNACKBARS.${stateType.toUpperCase()}.TITLE`,
      null,
      `EXPERIENCE.CONTENT_TEMPLATES.IMPORT.SNACKBARS.${stateType.toUpperCase()}.DESCRIPTION`,
      { fileName }
    );
  }
}

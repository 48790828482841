import { Directive, ElementRef, Input, OnDestroy } from '@angular/core';

import { ResizeObserverHelper } from './resize-observer-helper';
import { ReactiveComponent } from '../util/reactive-component';

/**
 * A directive which registers its element with a resizeObserver and adds an according overlay class in case of the element having some overlay applied.
 * There is only one instance of a resize observer that manages multiple elements.
 */
@Directive({ selector: '[hasOverflow]', standalone: true })
export class HasOverflowDirective extends ReactiveComponent implements OnDestroy {

  /** Specify the debounce time of the resize events as there will be a lot of events during manual resizing or animations. */
  @Input() public resizeDebounce = 100;
  /** Specify which overflow directions to listen to. Default is listen only to vertical overflow (aka `y`). */
  @Input() public overflowDirection: 'xy' | 'x' | 'y' = 'y';
  /** Optionally specify a different class name prefix to use. Default is `has-overflow`, which will result in `has-overflow-x` and `has-overflow-y` classes to be added. */
  @Input() public classNamePrefix = 'has-overflow';

  private resizeObserverHelper: ResizeObserverHelper;

  constructor(element: ElementRef) {
    super();

    this.resizeObserverHelper = new ResizeObserverHelper(element.nativeElement, this.resizeDebounce, this.unsubscribe$);

    this.resizeObserverHelper.startListen().subscribe(entry => {
      const el = entry.target;
      const classesToAdd: string[] = [];
      const classesToRemove: string[] = [];
      const xClass = this.classNamePrefix + '-x';
      const yClass = this.classNamePrefix + '-y';

      if (this.overflowDirection === 'x' || this.overflowDirection === 'xy') {
        HasOverflowDirective.checkForOverflow(el.clientWidth, el.scrollWidth, xClass, classesToAdd, classesToRemove);
      }

      if (this.overflowDirection === 'y' || this.overflowDirection === 'xy') {
        HasOverflowDirective.checkForOverflow(el.clientHeight, el.scrollHeight, yClass, classesToAdd, classesToRemove);
      }

      classesToAdd.length > 0 && element.nativeElement.classList.add(...classesToAdd);
      classesToRemove.length > 0 && element.nativeElement.classList.remove(...classesToRemove);
    });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.resizeObserverHelper.cleanup();
  }

  private static checkForOverflow(size: number, scrollSize: number, className: string, classesToAdd: string[], classesToRemove: string[]): void {
    const overflowX = size < scrollSize;
    if (overflowX) {
      classesToAdd.push(className);
    } else {
      classesToRemove.push(className);
    }
  }
}

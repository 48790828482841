import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

import { IconConfiguration } from '@celum/common-components';

import { Avatar } from '../../model/avatar/avatar';
import { Languages } from '../../model/languages';
import { User } from '../../model/user/user';

@Component({
             selector: 'systembar-avatar',
             templateUrl: './avatar-component.html',
             styleUrls: ['./avatar-component.less'],
             changeDetection: ChangeDetectionStrategy.OnPush,
             encapsulation: ViewEncapsulation.None,
             standalone: false
           })
export class AvatarComponent {

  @Input() public avatar: Avatar;

  @Input() public languages: Languages;

  @Input() public user: User;

  @HostBinding('class.systembar-avatar') public hostCls = true;

  public logOutIconConfiguration = new IconConfiguration('logout').withIconDimensions(20, 20);

  public selectClicked(event: Event): void {
    event.stopPropagation();
  }
}

import { Directive, EventEmitter, OnDestroy, Output } from '@angular/core';

@Directive({
             selector: '[celum-on-destroy]',
             standalone: false
           })
export class OnDestroyDirective implements OnDestroy {

  @Output() public readonly destroyed = new EventEmitter<void>();

  public ngOnDestroy(): void {
    this.destroyed.emit();
  }

}

import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors } from '@angular/forms';

export function rangeNumber(fg: AbstractControl): ValidationErrors {
  const start = fg.get('from').value;
  const end = fg.get('to').value;

  return start !== null && end !== null && start <= end ? null : start > end ? { rangeError: true } : null;
}

@Directive({
             selector: '[rangeNumber][ngModel]',
             providers: [
               {
                 provide: NG_VALIDATORS,
                 useValue: rangeNumber,
                 multi: false
               }
             ],
             standalone: false
           })
export class RangeNumberValidator {

}

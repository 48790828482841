import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS } from '@angular/forms';

import { DataUtil } from '@celum/core';

export function notEmpty(c: AbstractControl): any {
  if (!DataUtil.isEmpty(c.value)) {
    return null;
  }

  return {
    notEmpty: {
      valid: false,
      messageKey: 'VALIDATION.NOT_EMPTY',
      params: {}
    }
  };
}

@Directive({
             selector: '[notEmpty][ngModel]',
             providers: [
               {
                 provide: NG_VALIDATORS,
                 useValue: notEmpty,
                 multi: true
               }
             ],
             standalone: false
           })
export class NotEmptyValidator {

}

import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { isEqualSimpleObject, LocalizedValue } from '@celum/core';

import { currentLocaleStream } from './current-locale-stream';
import { instantTranslate } from './translate-instant';
import { TranslationHelper } from './translation-helper';

interface TranslationResult {
  result: string;
  input: string | LocalizedValue;
  paramLanguage: string;
  isGuessedTranslation: boolean;
}

/**
 * Picks right language from language object or translates string
 *  - Called with plain string: Route through translate service and serve translated value which updates automatically on language changes
 *  - Called with language object ({ de: 'Hallo', en: 'Hello' }) - picks right language according to user/default - language. Does not translate result!
 *
 *  'NOVA.CUSTOM.KEY' => 'Resolved key value'
 *  { de: 'Hallo', en: 'Hello' } => 'Hello' (if language is english)
 *  { de: 'NOVA.KEY.GERMAN', en: 'NOVA.KEY.ENGLISH' } => 'NOVA.KEY.ENGLISH' => this will not be translated - chain translate pipe if it has to be.
 */
@Pipe({
        name: 'language',
        pure: false // has to be false as it can be called with same params but current language changed
        ,
        standalone: false
      })
export class LanguagePipe implements OnDestroy, PipeTransform {

  private currentResult: TranslationResult;
  private changeSubscription: Subscription;

  constructor(private changeDetector: ChangeDetectorRef, private translateService: TranslateService) {
  }

  /**
   * Transform given input
   * @param input string or localized value
   * @param languageKey - only for LocalizedValue!! - language which should be used for translating (if not set, ui-language is used)
   * @param useFallback Specify if there should be fallbacks if no value for given language is found
   * @param interpolationParams which will be used if string is passed as string
   * @return a 'best-guess' string + change detection will be called again if value changes or best guess was not correct
   */
  public transform(input: string | LocalizedValue, languageKey?: string, useFallback = true, interpolationParams?: object): string {
    if (!input) {
      return '';
    }

    const alreadyEvaluated = LanguagePipe.isAlreadyEvaluated(this.currentResult, input, languageKey);
    if (alreadyEvaluated && !this.currentResult.isGuessedTranslation) {
      return this.currentResult.result || '';
    }

    // The instant translation is not always 100% correct as it's using the translate service's instant translation for strings or assumes the language
    // variants for objects. But this should be fine for 90% of the cases, so we set the value, start the streams and only run change detection again if
    // the 'real' value is different from the guessed one. (see updateValue - method)
    const instantTranslation = this.getBestGuessInstantTranslation(input, languageKey, useFallback);
    this.updateValue(instantTranslation, input, languageKey, true);

    this.changeSubscription && this.changeSubscription.unsubscribe();
    this.changeSubscription = this.asyncResult(input, languageKey, useFallback, interpolationParams)
                                  .subscribe(translated => this.updateValue(translated, input, languageKey, false));

    return this.currentResult.result || '';
  }

  public ngOnDestroy(): void {
    this.changeSubscription && this.changeSubscription.unsubscribe();
  }

  /**
   * Returns the stream of results which is updating if display language or default language changes.
   * @param value Input value (string or localized value)
   * @param languageKey Optional - only for LocalizedValue!! - use this language for translation regardless of default languages etc.
   * @param useFallback Specify if there should be fallbacks if no value for given language is found
   * @param interpolationParams which will be used if string is passed as string
   */
  private asyncResult(value: string | LocalizedValue, languageKey?: string, useFallback?: boolean, interpolationParams?: object): Observable<string> {
    if (!value) {
      return of('');
    }

    let translated$: Observable<string>;
    if (typeof value === 'string') {
      translated$ = this.translateService.stream(value, interpolationParams);
    } else {
      translated$ = currentLocaleStream(this.translateService).pipe(
        map(displayLang => TranslationHelper.evaluateLocalizedValue(value, languageKey, displayLang, this.translateService.defaultLang, useFallback))
      );
    }

    return translated$.pipe(distinctUntilChanged());
  }

  private getBestGuessInstantTranslation(value: string | LocalizedValue, languageKey: string, useFallback: boolean, interpolationParams?: object): string {
    return instantTranslate(this.translateService, value, this.translateService.currentLang, this.translateService.defaultLang, languageKey, useFallback,
                            interpolationParams);
  }

  private updateValue(result: string, input: string | LocalizedValue, languageKey: string, isGuessedTranslation: boolean): void {
    const valueChanged = !this.currentResult || this.currentResult.result !== result;

    this.currentResult = {
      input,
      result,
      paramLanguage: languageKey,
      isGuessedTranslation
    };

    // Only run change detection if the value changed and the result is not guessed (guessed result is anyway returned immediately)
    if (valueChanged && !isGuessedTranslation) {
      this.changeDetector && this.changeDetector.markForCheck();
    }
  }

  private static isAlreadyEvaluated(currentResult: TranslationResult, input: string | LocalizedValue, paramLanguage: string): boolean {
    if (currentResult && currentResult.paramLanguage === paramLanguage) {
      return typeof input === 'string' ? input === currentResult.input : isEqualSimpleObject(input, currentResult.input);
    }

    return false;
  }
}

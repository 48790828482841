import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';

import { SnackbarComponent } from '../../model/snackbar-component';
import { SnackbarConfiguration } from '../snackbar/snackbar-configurations';

@Component({
             selector: 'simple-snackbar',
             templateUrl: './simple-snackbar.html',
             changeDetection: ChangeDetectionStrategy.OnPush,
             standalone: false
           })
export class SimpleSnackbar implements SnackbarComponent<SnackbarConfiguration> {

  @Output() public readonly dismiss = new EventEmitter<void>();

  public config: SnackbarConfiguration;

  constructor(private changeDetector: ChangeDetectorRef) {
  }

  public configure(config: SnackbarConfiguration): void {
    this.config = config;
    this.changeDetector.markForCheck();
  }

}

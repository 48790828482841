import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { CelumIcon } from './components/icon';
import { IconContent } from './components/icon-content';

@NgModule({
            imports: [
              CommonModule, TranslateModule, MatIconModule
            ],
            declarations: [
              CelumIcon, IconContent
            ],
            exports: [
              CelumIcon, IconContent, TranslateModule
            ],
            providers: [provideHttpClient(withInterceptorsFromDi())]
          })
export class CelumIconModule {
}

import { CelumPropertiesProvider } from '@celum/core';

export interface CelumExperienceProperties {
  apiUrl: string;
  pusher: {
    appKey: string;
    authUrl: string;
    enableLogging?: boolean;
  };
  librarySelectionSearchMode: 'nameStartsWith' | 'nameContains';
  cNameTargetDomain: string;
  domainLimit: number;
  pageLimit: number;
  contentTemplateImportLimit: number;
  contentTemplateImportSizeLimitInBytes: number;
  contentTemplateAllowIdmlImport: boolean;
  workHttpBaseAddress: string;
  portalApprovalFeatureEnabled: boolean;
  contentTemplatePeriodicSaveIntervalMs?: number;
  contentTemplateMaxSelectableLibraries?: number;
  contentTemplateAssetUploadEnabled?: boolean;
}

export class ExperienceProperties {
  public static get properties(): CelumExperienceProperties {
    const testingFallback = {
      apiUrl: `test-api`,
      pusher: { appKey: 'test-key', authUrl: 'test-url' },
      librarySelectionSearchMode: 'nameContains',
      cNameTargetDomain: 'test-domain',
      domainLimit: 5,
      pageLimit: 8,
      contentTemplateImportLimit: 4,
      contentTemplateAllowIdmlImport: true,
      contentTemplateImportSizeLimitInBytes: 10000,
      contentTemplatePeriodicSaveIntervalMs: 60000,
      contentTemplateMaxSelectableLibraries: 15
    };
    return CelumPropertiesProvider.properties.appProperties?.experience ?? testingFallback;
  }
}

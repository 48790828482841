import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  Optional,
  ViewEncapsulation
} from '@angular/core';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

@Component({
             selector: 'celum-fullscreen-dialog',
             templateUrl: './fullscreen-dialog.component.html',
             styleUrls: ['./fullscreen-dialog.component.less'],
             changeDetection: ChangeDetectionStrategy.OnPush,
             encapsulation: ViewEncapsulation.None,
             standalone: false
           })
export class CelumFullscreenDialogComponent {
  @HostBinding('class.celum-fullscreen-dialog') public hostCls = true;

  @Input() public title: string;
  @Input() public light: boolean;
  @Input() public loading: boolean;

  constructor(@Optional() private dialogRef: MatDialogRef<CelumFullscreenDialogComponent>) { }

  public close(): void {
    this.dialogRef.close();
  }

  public static dialogConfig(data?: object): MatDialogConfig {
    return {
      panelClass: 'celum-fullscreen-dialog-overlay-pane',
      data
    };
  }

}

import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';

import { IconConfiguration } from '../../icon/index';
import { CelumFormFieldSizes } from '../../inputs';

@Component({
             selector: 'lookup-area',
             templateUrl: './lookup-area.html',
             styleUrls: ['./lookup-area.less'],
             changeDetection: ChangeDetectionStrategy.OnPush,
             encapsulation: ViewEncapsulation.None,
             standalone: false
           })
export class LookupArea implements OnChanges, OnDestroy, OnInit {

  // keep this on top as classes might overwrite other bindings if this is not the first one
  @HostBinding('class')
  @Input() public extendable: 'extend-left' | 'extend-right' | 'extend-no' = 'extend-no';

  @Input() public debounceTime = 0;
  @Input() public formFieldSize: CelumFormFieldSizes = 'medium';
  @Input() public iconPosition: 'left' | 'right' = 'right';
  @Input() public placeholder = '';
  @Input() public value = '';
  /**
   * Provide a custom validation function that should return true if the current value is valid and false otherwise.
   * The lookup will discard any changes by the user that caused the input to get invalid.
   */
  @Input() public customValidation: (value: string) => boolean;

  @Output() public readonly focusChanged = new EventEmitter<boolean>();
  @Output() public readonly searchValue = new EventEmitter<string>();
  @Output() public readonly keyEvent = new EventEmitter<KeyboardEvent>();

  @HostBinding('class.lookup-area') public hostCls = true;

  public cancelIcon = IconConfiguration.medium('cancel-m', '').withColor('unset');

  @HostBinding('class.lookup-area--focused')
  public hasFocus = false;
  public searchIcon = IconConfiguration.medium('search-m', '').withColor('unset');
  public valueControl = new UntypedFormControl('');

  @ViewChild(MatInput, { static: false }) private inputElement: MatInput;

  private subscription: Subscription;
  private previousValue = '';

  constructor(private changeDetector: ChangeDetectorRef) {
  }

  @HostBinding('class.lookup-area--with-value')
  public get hasValue(): boolean {
    return !!this.valueControl.value;
  }

  @HostBinding('class.lookup-area--active')
  public get focusCls(): boolean {
    return this.hasFocus;
  }

  public ngOnInit(): void {
    this.updateValueChangeSubscriptions();
  }

  // eslint-disable-next-line @typescript-eslint/no-shadow
  public ngOnChanges({ value, debounceTime }: SimpleChanges): void {
    debounceTime && this.updateValueChangeSubscriptions();
    value && this.updateValueControlIfChanged(this.value);
  }

  public ngOnDestroy(): void {
    this.subscription && this.subscription.unsubscribe();
  }

  public clear(): void {
    this.updateValueControlIfChanged('');
  }

  public focus(): void {
    this.inputElement.focus();
  }

  public blur(): void {
    (this.inputElement as any)?._elementRef?.nativeElement?.blur();
  }

  public onFocus(focus: boolean): void {
    this.hasFocus = focus;
    this.focusChanged.emit(focus);
    this.changeDetector.markForCheck();
  }

  public onKeyDown(event: KeyboardEvent): void {
    this.keyEvent.emit(event);
  }

  private updateValueChangeSubscriptions(): void {
    this.subscription?.unsubscribe();
    this.subscription = this.valueControl.valueChanges.pipe(
      tap(searchTerm => {
        if (!this.validateSearchTerm(searchTerm)) {
          this.valueControl.setValue(this.previousValue); // revert value immediately if validation fails
        }
      }),
      filter(searchTerm => this.validateSearchTerm(searchTerm)),
      tap(searchTerm => this.previousValue = searchTerm),
      distinctUntilChanged(),
      debounceTime(this.debounceTime)
    ).subscribe(searchTerm => this.searchValue.emit(searchTerm));
  }

  private updateValueControlIfChanged(newValue: string): void {
    newValue !== this.valueControl.value && this.valueControl.setValue(newValue);
  }

  private validateSearchTerm(searchTerm: string): boolean {
    return this.customValidation?.(searchTerm) ?? true;
  }
}

import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, inject, OnInit, signal, ViewEncapsulation } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Observable } from 'rxjs';

import { CelumIconModule, IconConfiguration } from '@celum/common-components';

import { NotificationsCountService } from '../../services/notifications-count.service';

@Component({
             selector: 'systembar-notifications',
             templateUrl: './notifications-component.html',
             styleUrls: ['./notifications-component.less'],
             changeDetection: ChangeDetectionStrategy.OnPush,
            //  Needed for the overlay to show up correctly
             encapsulation: ViewEncapsulation.None,
             standalone: true,
             imports: [OverlayModule, CommonModule, MatBadgeModule, MatTooltipModule, CelumIconModule]
           })
export class NotificationsComponent implements OnInit {

  @HostBinding('class.systembar-notifications') public hostCls = true;

  protected notificationIconConfiguration = new IconConfiguration('notification').withIconSize(24).withColor('#FFF');;

  protected count$: Observable<string>;
  protected isPanelOpen = signal<boolean>(false);

  private notificationsCountService = inject(NotificationsCountService);

  public ngOnInit(): void {
    // TODO: Consider using a signal store when implementing WR2-15902
    this.count$ = this.notificationsCountService.pollForNotificationCount();
  }
}

import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidatorFn } from '@angular/forms';

export function minLength(minCharacterLength: number, trim = true): ValidatorFn {
  // do not return function inline -> ngc doesn't like that
  const fn = (control: AbstractControl): { [key: string]: any } => {
    const length: number = control.value ? (trim && control.value.trim ? control.value.trim().length : control.value.length) : 0;
    // field can be empty but if filled, meet the requirement. for required field, add "required" validator

    return length > 0 && length < minCharacterLength ? {
      minLength: {
        valid: false,
        messageKey: 'VALIDATION.MIN_LENGTH',
        params: {
          minLength: minCharacterLength,
          actualLength: length
        }
      }
    } : null;
  };

  return fn;
}

@Directive({
             selector: '[minLength][ngModel]',
             providers: [
               {
                 provide: NG_VALIDATORS,
                 useValue: minLength,
                 multi: true
               }
             ],
             standalone: false
           })
export class MinLengthValidator {

}

import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS } from '@angular/forms';

import { DataUtil } from '@celum/core';

// todo: this might have to be configurable... localization support!

export function isDate(c: AbstractControl): any {
  let validationResult = true;

  if (!DataUtil.isEmpty(c.value)) {
    // eslint-disable-next-line no-useless-escape
    validationResult = (c.value).match(/^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/);
  }

  return (validationResult) ? null : {
    isDate: {
      valid: false,
      messageKey: 'VALIDATION.IS_DATE',
      params: { format: 'yyyy/mm/dd' }
    }
  };
}

@Directive({
             selector: '[isDate][ngModel]',
             providers: [
               {
                 provide: NG_VALIDATORS,
                 useValue: isDate,
                 multi: true
               }
             ],
             standalone: false
           })
export class DateValidator {

}

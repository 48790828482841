import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { select, Store } from '@ngrx/store';
import { catchError, EMPTY, map, Observable, of, Subject, takeUntil, tap, throwError } from 'rxjs';
import { filter, mergeMap, switchMap, take, takeWhile } from 'rxjs/operators';

import { BrowserCheck, CelumPropertiesProvider, UUIDGenerator } from '@celum/core';
import { JobUploadProgress, UploadCallback, UploadErrorCallback } from '@celum/experience/shared';
import { BlobUploadConfig, BlobUploadEngine, getQueueProgressReport, getTicketProgress, ProgressStatus, UploadEvent, UploadService } from '@celum/ng2base';

/**
 * *_ATTACHED_* libraries come from an external source - mostly from SLIB - and thus readonly
 */
export enum AssetLibraryType {
  PORTAL_LIBRARY = 'PORTAL_LIBRARY', // used for `supporting files`
  PORTAL_ATTACHED_LIBRARY = 'PORTAL_ATTACHED_LIBRARY', // libraries attached in Portal Wizard
  DESIGN_THEME_LIBRARY = 'DESIGN_THEME_LIBRARY', // used for `design theme assets`
  DESIGN_THEME_ATTACHED_LIBRARY = 'DESIGN_THEME_ATTACHED_LIBRARY', // libraries attached in Design Theme Editor
  PENDING_LIBRARY = 'PENDING_LIBRARY', // used when asset upload is allowed before the entity is created
  ORG_LIBRARY = 'ORG_LIBRARY', // used for `organization assets`
  CONTENT_TEMPLATE_LIBRARY = 'CONTENT_TEMPLATE_LIBRARY',
  CONTENT_TEMPLATE_ATTACHED_LIBRARY = 'CONTENT_TEMPLATE_ATTACHED_LIBRARY'
}

export interface LibraryContext {
  libraryId: string;
  type: AssetLibraryType;
  portalId?: string;
  designThemeId?: string;
  contentTemplateId?: string;
}

export interface SingleUpload {
  libraryContext: LibraryContext;
  uploadJobId: string;
  name: string;
  size: number;
  file: File;
  ticket: string;
  containerId?: string;
  uploadUrl?: string;
  uploadTicketId?: string;
  uploadTicketIdentifier?: string;
  contentItemId: string;
  folderId?: string;
  assetId?: string;
}

/**
 * The upload confirmation request. Contains the upload ticket id, the upload ticket identifier and the library context.
 */
export type UploadConfirmationRequest = {
  uploadTicketId: string; // the upload ticket id
  uploadTicketIdentifier: string; // the upload ticket identifier, usually the same as the upload ticket id
  libraryContext: LibraryContext; // the {@link LibraryContext} for which the file upload should be confirmed
};

/**
 * The upload confirmation response. Contains the file id of the newly uploaded file.
 */
export type UploadConfirmationResponse = {
  fileId: string; // the id of the newly uploaded file
};

/**
 * The custom upload confirmation callback function. This function can be used to customize the upload confirmation request and
 * redirect it to a custom endpoint (e.g. for proxying the original confirmation request and decorate it with additional functionality).
 *
 * The UploadTicketId and {@link LibraryContext} are passed to this function as they're necessary for the upload confirmation request.
 * A successful response will contain the file id of the newly uploaded file.
 *
 * @param upload the upload confirmation request containing upload ticket id and library context
 * @returns an observable that emits the upload confirmation response, containing the file id of the newly uploaded file
 */
export type CustomUploadConfirmationFn = (upload: UploadConfirmationRequest) => Observable<UploadConfirmationResponse>;

type AssetIdAndName = {
  assetId?: string;
  name?: string;
};

type UploadLogicState = {
  confirmedTickets: string[];
  ticketAssetMap: { [key: string]: AssetIdAndName };
};

export interface UploadOptions {
  libraryContext: LibraryContext;
  folderId?: string;
  contentItemId?: string;
  uploadSource?: string; // unique identifier describing the source of the upload, upload snackbars are grouped by this
  uploadCallback?: UploadCallback; // callback to be called when individual file uploads are finished for an upload job or the upload job is cancelled
  uploadErrorCallback?: UploadErrorCallback; // callback to be called when any file upload fails and the upload job is finished
  customUploadConfirmationFn?: CustomUploadConfirmationFn; // can be used to customize the upload confirmation request
}

// Queues upload, prepares file, uploads it and confirms upload
@Injectable({ providedIn: 'root' })
export class UploadLogicService extends ComponentStore<UploadLogicState> {
  private cancellationSignal$ = new Subject<string>();
  private workingQueue$ = new Subject<SingleUpload>();
  private jobUploadOptionsMap: Map<string, UploadOptions>; // jobId / upload options
  private queue: Map<string, SingleUpload>; // ticket / upload
  private erroredUploads: SingleUpload[] = [];

  constructor(
    private http: HttpClient,
    private uploadService: UploadService,
    private engine: BlobUploadEngine,
    private store: Store<any>
  ) {
    super({ confirmedTickets: [], ticketAssetMap: {} });
    this.queue = new Map();
    this.jobUploadOptionsMap = new Map();

    this.handleUploads();
  }

  /**
   * Clear ticket info when not needed anymore
   * @param tickets which tickets should be removed from state
   */
  public clearTickets(tickets: string[]): void {
    const ticketNameMap = { ...this.get().ticketAssetMap };
    tickets.forEach(ticket => delete ticketNameMap[ticket]);
    this.patchState({ confirmedTickets: this.get().confirmedTickets.filter(ticket => !tickets.includes(ticket)), ticketAssetMap: ticketNameMap });
  }

  /**
   * Retry for failed uploads
   * @param jobId which job should be retried
   */
  public retryUpload(jobId: string): void {
    const uploads = this.erroredUploads.filter(upload => upload.uploadJobId === jobId);
    this.erroredUploads = this.erroredUploads.filter(upload => upload.uploadJobId !== jobId);

    // Create new uploads for same job
    this.uploadInternal(
      uploads.map(upload => upload.file),
      this.jobUploadOptionsMap.get(jobId),
      jobId
    );

    // Delete previous uploads
    uploads.forEach(upload => this.uploadService.removeUpload(upload.ticket));
  }

  /**
   * Cancel uploads for job
   * @param jobId which job should be canceled
   */
  public cancelRequest(jobId: string): void {
    const uploads = [...this.queue.values()].filter(upload => upload.uploadJobId === jobId);
    uploads.forEach(upload => {
      this.cancellationSignal$.next(upload.ticket);
      this.queue.delete(upload.ticket);
      this.uploadService.removeUpload(upload.ticket);
    });
  }

  /**
   *  Get upload progress of a job containing one or more tickets
   * @param jobId of the report
   */
  public getUploadProgressReport(jobId: string): Observable<JobUploadProgress> {
    return this.select(
      this.store.select(getQueueProgressReport(jobId)),
      this.select(state => state.confirmedTickets),
      this.select(state => state.ticketAssetMap),
      (progressReport, confirmedTickets, ticketNameMap) => ({
        total: progressReport.size,
        done: progressReport.done,
        itemProgress: progressReport.items.map(currentItem => ({
          ...currentItem,
          // finished if failed or successful AND!! confirmed
          isFinished: currentItem.status === ProgressStatus.Error || confirmedTickets.includes(currentItem.identifier),
          assetId: ticketNameMap[currentItem.identifier]?.assetId,
          name: ticketNameMap[currentItem.identifier]?.name
        })),
        identifier: jobId,
        progressStatus: progressReport.status,
        // all is finished if every progress either failed or successfully uploaded AND!! confirmed
        allFinished: progressReport.items.reduce(
          (allConfirmed, currentItem) => allConfirmed && (confirmedTickets.includes(currentItem.identifier) || currentItem.status === ProgressStatus.Error),
          true
        )
      })
    );
  }

  /**
   * Initiates a file upload
   * @param files      which should be uploaded
   * @param options    options for the upload
   */
  public upload(files: File[], options: UploadOptions): string {
    return this.uploadInternal(files, options);
  }

  private requestAndExecuteUpload(upload: SingleUpload): Observable<SingleUpload> {
    return this.requestUploadUrl(upload).pipe(switchMap(singleNovaUpload => this.executeUpload(singleNovaUpload)));
  }

  private executeUpload(upload: SingleUpload): Observable<SingleUpload> {
    const url = new URL(upload.uploadUrl);
    const config: BlobUploadConfig = {
      engine: this.engine,
      blobName: upload.uploadTicketIdentifier,
      containerName: upload.containerId,
      protocol: url.protocol,
      host: url.host,
      sasToken: url.search,
      maxTries: 1
    };

    // create as a function to reuse it if the upload fails
    this.uploadService.upload([upload.ticket], config);

    return this.store.pipe(
      select(getTicketProgress(upload.ticket)),
      takeWhile(progress => !!progress), // stop if upload was canceled
      filter(({ status }) => status === ProgressStatus.Error || status === ProgressStatus.Success),
      switchMap(progress => (progress.status === ProgressStatus.Error ? throwError(() => ({ error: progress.error })) : of(upload))),
      take(1)
    );
  }

  /**
   * Calls the create endpoint to get upload information like upload url, token etc.
   * @param libraryContext context of the library we are uploading for
   * @param fileName name of the file
   * @param fileSize size of the file
   * @param contentItemId optionally define the id of the content item (in case the asset is being updated)
   * @param folderId optionally define the folder where the asset should be uploaded to
   */
  private requestUpload(
    libraryContext: LibraryContext,
    fileName: string,
    fileSize: number,
    contentItemId?: string,
    folderId?: string
  ): Observable<{ id: string; identifier: string; baseUrl: string; sasToken: string; containerId: string }> {
    const httpRequestBody = {
      libraryContext,
      fileName,
      fileSize,
      contentItemId,
      folderId
    };

    return this.http.post<any>(`${CelumPropertiesProvider.properties.appProperties.experience.apiUrl}/assets/upload-ticket/create`, httpRequestBody);
  }

  private requestUploadUrl(upload: SingleUpload): Observable<SingleUpload> {
    return this.requestUpload(upload.libraryContext, upload.name, upload.size, upload.contentItemId, upload.folderId).pipe(
      map(({ id, identifier, baseUrl, sasToken, containerId }) => {
        return {
          ...upload,
          containerId,
          uploadTicketId: id,
          uploadTicketIdentifier: identifier,
          uploadUrl: baseUrl + sasToken
        };
      })
    );
  }

  /**
   * Calls the confirm endpoint to confirm successful upload
   * @param upload contains information about the upload and needed for confirm payload
   */
  private requestUploadConfirmation(upload: SingleUpload): Observable<SingleUpload> {
    const customUploadConfirmationFn = this.jobUploadOptionsMap.get(upload.uploadJobId)?.customUploadConfirmationFn;

    const uploadConfirmationRequest: UploadConfirmationRequest = {
      libraryContext: upload.libraryContext,
      uploadTicketId: upload.uploadTicketId,
      uploadTicketIdentifier: upload.uploadTicketIdentifier
    };

    if (customUploadConfirmationFn) {
      return customUploadConfirmationFn(uploadConfirmationRequest).pipe(map(confirmation => ({ ...upload, assetId: confirmation.fileId })));
    }

    return this.http
      .post<UploadConfirmationResponse>(
        `${CelumPropertiesProvider.properties.appProperties.experience.apiUrl}/assets/upload-ticket/confirm`,
        uploadConfirmationRequest
      )
      .pipe(map(confirmation => ({ ...upload, assetId: confirmation.fileId })));
  }

  private handleUploads(): void {
    // Extracted to own observable to be able to set the concurrent number of uploads in the queue for the whole process
    const processUpload = (upload: SingleUpload) => {
      return this.queue.has(upload.ticket)
        ? of(upload).pipe(
            switchMap(singleUpload => this.requestAndExecuteUpload(singleUpload)),
            switchMap(singleUpload => this.requestUploadConfirmation(singleUpload)),
            tap(singleUpload => this.patchState({ ticketAssetMap: { ...this.get().ticketAssetMap, [singleUpload.ticket]: singleUpload } })),
            catchError(error => {
              // Manually updating the upload status, because upload failed after actual upload
              this.store.next(new UploadEvent([{ type: 'failed', ticket: upload.ticket, error }]));
              this.erroredUploads.push(upload);
              return EMPTY;
            }),
            // cancel any current and following upload for this job
            takeUntil(this.cancellationSignal$.pipe(filter(id => id === upload.ticket)))
          )
        : EMPTY;
    };

    this.workingQueue$.pipe(mergeMap(upload => processUpload(upload), CelumPropertiesProvider.properties.maxConcurrentUploads)).subscribe(singleUpload => {
      this.queue.delete(singleUpload.ticket);
      this.patchState({ confirmedTickets: [...this.get().confirmedTickets, singleUpload.ticket] });
    });
  }

  private queueUploads(uploads: SingleUpload[]): void {
    uploads.forEach(upload => {
      this.queue.set(upload.ticket, upload);
      this.workingQueue$.next(upload);
    });
  }

  /**
   * Prepares the upload in the core upload service and in this service
   * @param files   that should be uploaded
   * @param options options for the upload
   * @param jobId   if job already exists
   */
  private uploadInternal(files: File[], options: UploadOptions, jobId?: string): string {
    const uploadJobId = jobId ?? UUIDGenerator.generateId();
    const tickets = this.uploadService.prepareUploads(files, uploadJobId);
    this.jobUploadOptionsMap.set(uploadJobId, options);

    const osInfo = BrowserCheck.getOsInfo();
    const convertToNFCEnabled = (osInfo === 'MacOS' || osInfo === 'iOS') && BrowserCheck.isFirefox();

    const uploads: SingleUpload[] = tickets.map((ticket, idx) => {
      const { name: rawName, size, type } = files[idx];
      const { libraryContext, contentItemId, folderId } = options;

      const name = convertToNFCEnabled ? files[idx].name.normalize('NFC') : rawName;

      this.patchState({ ticketAssetMap: { ...this.get().ticketAssetMap, [ticket]: { name } } });

      return { libraryContext, uploadJobId, ticket, name, size, type, file: files[idx], contentItemId, folderId };
    });

    this.queueUploads(uploads);
    return uploadJobId;
  }
}

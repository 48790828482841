import { Type } from '@angular/core';
import { Schema, schema } from 'normalizr';

import { Entity, EntityTranslator } from './entity';
import { EntityResolver } from '../entity-resolver/entity-resolver';
import { PaginationTranslatorFn } from '../entity-result/pagination-translator';

/** Defines a function that takes in an old and new value and returns a result of the same type */
type UpdateFunction<T> = (prev: T, next: T) => T;

/** Defines a type that mirrors the given value type but exchanges the property value types to an update function of that specific key */
type UpdateStrategyRecord<T> = { [K in keyof T]?: UpdateFunction<T[K]> };

/**
 * Define an update strategy which governs how a new value should be merged together with an existing one.
 * It is mostly used for defining how an entity in the store should be updated if a new one arrives.
 * It can be a function taking in the old and new value `(prev: T, next: T) => merge(prev, next)` or a more granular
 * update mechanism based on the entity properties `{ name: (prev: string, next: string) => merge(prev, next) }`.
 * To simplify working with update strategies, it is advised to use helpers like `ignore`, `overwrite`, `mergeShallow` or `mergeDeep`
 * `{ name: overwrite, creatorId: ignore, nestedValue: mergeShallow, deepNestedValue: mergeDeep}`.
 * Attributes that are not specified in the strategy will be registered with the overwrite function and therefore always be updated.
 */
export type UpdateStrategy<T> = UpdateFunction<T> | UpdateStrategyRecord<T>;

/**
 * Same as {@see UpdateStrategy} with the addition that if a {@see UpdateStrategyRecord} is passed, it also needs to have a property called inheritStrategy.
 * This is used in cases where we want to overwrite a strategy to define if the existing one should still be considered.
 * If inheritStrategy is false, only the given {@see UpdateStrategyRecord} is used and missing properties fall back to the overwrite strategy.
 * If inheritStrategy is true, the strategy from the EntityRegistry will be used and be overwritten by properties set by the {@see UpdateStrategyRecord}.
 */
export type UpdateStrategyDeviation<T> = UpdateFunction<T> | (UpdateStrategyRecord<T> & { inheritStrategy: boolean });

/**
 * Configuration that governs how a property of an entity, which references another entity, should be resolved.
 * The config can be set as part of a {@see ResolveStrategy} in the {@see EntityRegistration} or {@see EntityRegistrationDeviations}
 */
export interface ResolveConfig {
  /** Specify which entity resolver service should be used to resolve the property that this configuration is set on in the {@see EntityRegistration}. */
  resolver: Type<EntityResolver>;
}

/** Type that is used for a single property of an {@see ResolveStrategy}, can either be a resolver directly or a config object which allows for more specific behaviour */
export type ResolverOrConfig = Type<EntityResolver> | ResolveConfig;
/** Same as {@see ResolverOrConfig} but all configuration options are optional */
export type ResolverOrConfigDeviation = Type<EntityResolver> | Partial<ResolveConfig>;

/**
 * Properties of an entity that are either of type string or string[] can be id-references to other entities.
 * The {@see ResolveStrategy} defines which properties are the referencing ones and how they should be resolved by specifying a {@see ResolveConfig} for each
 * of them. The strategy can be set on the {@see EntityRegistration} or {@see EntityRegistrationDeviations}.
 *
 * Example: take the creatorId property and resolve it using the UserService
 * {
 *   creatorId: { resolver: UserService }
 * }
 */
export type ResolveStrategy<T = Entity, C = ResolverOrConfig> = { [K in keyof T]?: T[K] extends string | string[] ? C : never };

/**
 * Same as {@see ResolveStrategy} with the addition that it also needs to have a property called inheritStrategy.
 * This is used in cases where we want to overwrite a strategy to define if the existing one should still be considered.
 * If inheritStrategy is false, only the given {@see ResolveStrategy} is used and missing properties will not be resolved.
 * If inheritStrategy is true, the strategy from the EntityRegistry will be used and be overwritten by properties set by the {@see ResolveStrategy}.
 */
export type ResolveStrategyDeviation<T> = ResolveStrategy<T, ResolverOrConfigDeviation> & { inheritStrategy: boolean };

/** A registration interface which describes attributes and the behaviour of a specific entity. */
export interface EntityRegistration<E extends Entity> {
  /** A type key which should be unique to this entity type */
  typeKey: string;
  /** A translator function which converts a raw json to an object which satisfies the entity interface */
  translator: EntityTranslator<E>;
  /** A default schema which describes the expected shape of data returned for this entity. */
  schema: schema.Entity<E>;
  /**
   * A default update strategy which defines how the entity should be updated if a new one arrives.
   * If no strategy is set, it will default to the overwrite function and therefore always overwrite all properties of the entity.
   */
  updateStrategy?: UpdateStrategy<E>;
  /**
   * A default resolve strategy which defines which properties from the entity should be resolved via an {@see EntityResolver}.
   * Resolved entities will land in the store and can be accessed through relations.
   * Resolving takes place in the background and is not connected to the originating call which means that there is no error
   * handling (has to be done in the resolver) and the originating call will not wait until resolvers have completed.
   * If no strategy is set, no properties will be resolved.
   */
  resolveStrategy?: ResolveStrategy<E>;
}

/**
 * Defines a deviation from default values for the given entity type E.
 * Example: {
 *   updateStrategy: {
 *     inheritStrategy: true,
 *     name: ignore
 *   },
 *   resolveStrategy: {
 *     inheritStrategy: true,
 *     libraryIds: LibraryService
 *   }
 * }
 */
export type EntityRegistrationDeviation<E extends Entity> = { updateStrategy?: UpdateStrategyDeviation<E>; resolveStrategy?: ResolveStrategyDeviation<E> };

/**
 * Defines a set of {@see EntityRegistrationDeviation} to deviate for multiple entity types at once
 * Example: {
 *   ASSET: assetDeviation,
 *   NODE: nodeDeviation
 * }
 */
export type EntityRegistrationDeviations = Record<string, EntityRegistrationDeviation<any>>;

/**
 * Meta information which is used to govern and modify the behaviour when working with incoming entities
 */
export interface ResultMetaInfo {
  /**
   * The schema that will be used to normalize the backend response.
   * Keep in mind that if {@see resultKey} is set, the schema will operate on that object instead of the whole response.
   */
  schema: Schema;

  /**
   * Define the entity types that should be translated and added to the store. If not set, all entities will be handled.
   */
  entityTypesToProcess?: Set<string>;

  /**
   * Define a property key which defines the actual relevant data from the response. This can be used if the relevant information is nested e.g. in an
   * "results" property. If a resultKey is defined, the given {@see schema} will only operate on the value of it as well.
   */
  resultKey?: string;

  /**
   * Specify deviations from the default entity handling.
   * See {@see EntityRegistrationDeviations} for more details
   *
   * Examples
   * deviation: deviate<Asset>(ASSET_TYPE_KEY, { ... })
   * deviation: { ASSET: deviate<Asset>({ ... }), NODE: deviate<Node>({ ... })  }
   */
  deviation?: EntityRegistrationDeviations;

  /**
   * Define a pagination translator that should be used instead of the global one provided by the app-level injection token {@see PAGINATION_TRANSLATOR_FN}
   */
  paginationTranslator?: PaginationTranslatorFn;
}

/*
 * Get the configured default schema of an entity
 * @param typeKey of the entity which schema should be returned
 */
export function schemaOf(typeKey: string): Schema {
  return EntityRegistry.get(typeKey).schema;
}

/**
 * Helper function to create {@see EntityRegistrationDeviation} and {@see EntityRegistrationDeviations} in a type safe manner.
 * Examples
 * deviate<Asset>(ASSET_TYPE_KEY, { updateStrategy: {...}}) -> generates a {@see EntityRegistrationDeviations}
 * deviate<Asset({ updateStrategy: {...}}) -> generates a {@see EntityRegistrationDeviation}
 */
export function deviate<E extends Entity>(typeKey: string, deviation: EntityRegistrationDeviation<E>): EntityRegistrationDeviations;
export function deviate<E extends Entity>(deviation: EntityRegistrationDeviation<E>): EntityRegistrationDeviation<E>;
export function deviate<E extends Entity>(deviationOrType: string | EntityRegistrationDeviation<E>,
                                          deviation?: EntityRegistrationDeviation<E>): EntityRegistrationDeviation<E> | EntityRegistrationDeviations {
  if (typeof deviationOrType === 'string') {
    return { [deviationOrType]: deviation };
  } else {
    return deviationOrType;
  }
}

class EntityRegistry {
  private static registry = new Map<string, EntityRegistration<any>>();

  public static register<T extends Entity>(registration: EntityRegistration<T>): void {
    this.registry.set(registration.typeKey, registration);
  }

  /**
   * Retrieve a registration based on the typeKey. If "deviations" is set, they will be considered for the returned result.
   * @param typeKey for which this registration should be saved
   */
  public static get<E extends Entity>(typeKey: string): EntityRegistration<E> {
    if (!this.registry.has(typeKey)) {
      throw new Error(
        `EntityRegistry: There is no registration entry for typeKey "${typeKey}". Please make sure to call EntityRegistry.register(...) for it or exclude this entity from being processed by explicitly specifying "entityTypesToProcess" in the meta information for the ResultConsumerService.consume call.`
      );
    }

    return { ...this.registry.get(typeKey) } as EntityRegistration<E>;
  }
}

/**
 * Why is this necessary?
 *
 * Webpack creates a scope for each bundle it creates. All code you create only exists (and is accessible) inside of this scope. This also applies to classes
 * and their static properties/functions. For example, consider extensions for Nova. If you have extensions loaded that do NOT share their dependencies with
 * Nova, they will provide their own version of all classes and functions. These classes and functions only exist inside the scope of the extension (or the
 * extensions if some of them share dependencies). Even static classes. This proxy allows to make sure that static classes are actually behaving like you would
 * expect it (and as it would be if it wouldn't be for webpack) regardless of how many different webpack bundles are loaded on the side.
 */
let reg = EntityRegistry;

if (globalThis) {
  if (!(globalThis as any).CelumEntityRegistry) {
    (globalThis as any).CelumEntityRegistry = EntityRegistry;
  } else {
    reg = (globalThis as any).CelumEntityRegistry;
  }
}

export { reg as EntityRegistry };

import {
  AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, Input, OnChanges, OnDestroy, Optional, SimpleChanges,
  TemplateRef, ViewChild, ViewEncapsulation
} from '@angular/core';
import { DragulaService } from 'ng2-dragula';

import { isSmartphoneScreen } from '@celum/core';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import autoScroll from '@celum/dom-autoscroller';

import { ListSelectableDirective } from '../../directives/list-selectable.directive';
import { SwipeScrollHandlerDirective } from '../../directives/swipe-scroll-handler.directive';
import { AbstractList } from '../abstract-list';

export type HasId = {
  id: string;
};

@Component({
             selector: 'celum-simple-list',
             templateUrl: './simple-list.html',
             styleUrls: ['./simple-list.less'],
             hostDirectives: [
               {
                 directive: SwipeScrollHandlerDirective,
                 inputs: ['reloadOnScrollToTop: reloadOnScrollToTop']
               }
             ],
             encapsulation: ViewEncapsulation.None,
             changeDetection: ChangeDetectionStrategy.OnPush,
             standalone: false
           })
export class CelumSimpleList<E = HasId> extends AbstractList<E> implements OnChanges, AfterViewInit, OnDestroy {

  @Input() public ddGroup: string;
  @Input() public customItemClass = '';
  @Input() public itemTemplate: TemplateRef<any>;
  @Input() public darkMode: boolean;

  @ViewChild('nativeScroller', { static: false, read: ElementRef }) public nativeScroller: ElementRef;

  @HostBinding('class.simple-list') public hostCls = true;

  protected scroller: any;

  constructor(@Optional() private dragulaService: DragulaService, protected changeDetectorRef: ChangeDetectorRef,
              @Optional() protected listSelectionDirective: ListSelectableDirective<E>, private el: ElementRef) {
    super(changeDetectorRef);
  }

  /** Optionally define a compare function. By default, ids are compared (if E provides "id" property, or elements itself are compared. */
    // eslint-disable-next-line eqeqeq
  @Input() public compareFn: (a: E, b: E) => boolean = (a, b) => (CelumSimpleList.hasId(a) && CelumSimpleList.hasId(b)) ? a.id === b.id : a === b;

  @Input() public trackByFn: (index: number, item: E) => any = (index, item) => item;

  public ngAfterViewInit(): void {
    // if no selection handler provided, try to take it from directive
    this.selectionHandler = this.selectionHandler ?? this.listSelectionDirective;

    super.ngAfterViewInit();

    const { dragulaService, ddGroup } = this;

    if (ddGroup) {
      const margin = isSmartphoneScreen() ? 20 : 60;
      // making lists autoscrollable, which means that they can be scrolled by dragging element to the top or bottom
      this.scroller = autoScroll([this.nativeScroller.nativeElement], {
        margin,
        maxSpeed: 20,
        scrollWhenOutsideY: true,
        scrollWhenOutsideX: false,
        autoScroll(): any {
          if (ddGroup) {
            const drake = dragulaService.find(ddGroup);
            return this.down && drake?.drake?.dragging;
          }
          return undefined;
        }
      });
    }

    // used for infinite-scroll
    this.scrollContainer = this.el.nativeElement.querySelector('.ps');
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.scroller && this.scroller.destroy();
  }

  public scrollToTop(): void {
    if (this.nativeScroller) {
      this.nativeScroller.nativeElement.scrollTop = 0;
    }
  }

  protected isSelected(selection: E[], item: E, compareFn: (a: E, b: E) => boolean): boolean {
    return !!selection.find(selectedItem => compareFn(selectedItem, item));
  }

  private static hasId(a: any | HasId): a is HasId {
    // eslint-disable-next-line eqeqeq
    return a.id != null;
  }
}
